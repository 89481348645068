import { createEffect, For, Match, mergeProps, Switch } from 'solid-js';
import { ColorName, textColorClasses } from '../../style/color-classes';
import { ErrorCatcher } from '../../tools/error-catcher';
import { FontSize, ListProps } from './list.d';
import { Dynamic } from 'solid-js/web';
import clsx from 'clsx';
import { TextProps } from '../text/text.types';

const defaultProps = {
    fontSize: 'l' as FontSize,
    ordered: false,
};

const fontSizeClasses: Record<TextProps['fontSize'], string> = {
    xs: 'text-paragraphXS',
    s: 'text-paragraphS',
    m: 'text-paragraphM',
    l: 'text-paragraphL',
    xl: 'text-paragraphXL',
    xxl: 'text-paragraphXXLMobile',
};

export const List = (componentProps: ListProps) => {
    const props = mergeProps(defaultProps, componentProps);
    const fontSizeClassName = props.fontSize && fontSizeClasses[props.fontSize];
    const colorClassName = props.color && textColorClasses[props.color as ColorName];

    const listItemClasses = 'pl-2 pb-0 mb-4 [&_a]:underline';
    const tag = props.ordered ? 'ol' : 'ul';

    return (
        <ErrorCatcher componentName="List">
            <Dynamic
                component={tag}
                class={clsx(
                    { 'list-disc': tag === 'ul' },
                    { 'list-decimal': tag === 'ol' },
                    fontSizeClassName,
                    colorClassName,
                    'max-w-textMaxWidth mb-[2em] mt-4 w-full pl-6',
                )}
            >
                <Switch>
                    <Match when={props.items}>
                        <For each={props.items}>
                            {(item: string) => {
                                return <li class={listItemClasses} innerHTML={item} />;
                            }}
                        </For>
                    </Match>
                    <Match when={props.children}>
                        <For each={props.children}>
                            {(child: string) => {
                                return <li class={listItemClasses}>{child}</li>;
                            }}
                        </For>
                    </Match>
                </Switch>
            </Dynamic>
        </ErrorCatcher>
    );
};

List.parseProps = (atts: any) => {
    const parsedListItems = atts?.content?.map((item: any) => {
        let parsedItem = item.innerHTML;
        parsedItem = parsedItem.replace('\n<li>', '');
        parsedItem = parsedItem.replace('</li>\n', '');
        return parsedItem;
    });

    return {
        items: parsedListItems,
        fontSize: atts.fontSize || 'l',
        displayRedVerticalLine: atts.displayRedVerticalLine,
        color: atts.color?.name || null,
        ordered: atts.ordered || false,
    };
};
