import type { ResponsiveOverride } from '../section-types';
import { ResponsiveClasses, responsiveClasses, ResponsiveOverrideLocation, ResponsiveOverrideScreen } from './responsive-classes';

export const getResponsiveOverrideClasses = (responsiveOverrides: ResponsiveOverride[]) => {
    const responsiveOverrideClasses: string[] = [];

    responsiveOverrides?.forEach((responsiveOverride: ResponsiveOverride) => {
        const { screen, location, size } = responsiveOverride;

        // By using the info from the responsive override item, get
        // the class we want to add from the responsiveClasses object.
        const responsiveClass = responsiveClasses
            [screen as keyof ResponsiveClasses]
            [location as keyof ResponsiveOverrideScreen]
            [size as keyof ResponsiveOverrideLocation];

        responsiveOverrideClasses.push(responsiveClass);
    });

    return responsiveOverrideClasses;
};
