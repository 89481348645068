import { useSearchParams } from '@solidjs/router';
import clsx from 'clsx';
import { For, Show, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ButtonColorName } from '../../style/color-classes';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Button } from '../../ui-components/button/button';
import { GET_POSTS_QUERY } from './blog-archive-queries';
import { BlogCategory } from './blog-archive-types';
import { BlogListItem } from './blog-list-item';

const DEFAULT_LIMIT = 20;

const tagClasses = (slug: string, category?: string) =>
    clsx(
        'border-1 mb-xxs border-primary-600 px-s text-primary-600 hover:bg-primary-600 hover:text-primary-100 text-medium inline-block rounded-l focus:rounded-l py-[0.31rem] transition-colors duration-200',
        { 'bg-primary-600': slug === category },
        { '!text-primary-100': slug === category },
    );

export const BlogArchive = ({ limit = DEFAULT_LIMIT, offset = 0, blogCategoriesFromHook = [] }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { createCachedResource } = useContext(AppContext);
    const [selectedFilter, setSelectedFilter] = createSignal<string>();
    const [totalAmount, setTotalAmount] = createSignal(limit);

    const updateQueryVars = (newFilter = selectedFilter()) => {
        setQueryVars({
            tags: searchParams.category || newFilter,
            limit: totalAmount(),
            offset: 0,
        });
    };

    const [queryVars, setQueryVars] = createSignal({
        tags: searchParams.category || selectedFilter() || undefined,
        limit: totalAmount(),
        offset,
    });

    const [postsContent] = createCachedResource(GET_POSTS_QUERY, queryVars, true);

    const handleLoadMoreClick = () => {
        setTotalAmount((prevAmount) => prevAmount + limit);
        updateQueryVars();
    };

    const handleFilterClick = (slug: string | undefined) => {
        setSelectedFilter(slug);

        setSearchParams({ category: slug }, { replace: true });
        setQueryVars({
            tags: slug,
            limit: totalAmount(),
            offset: 0,
        });
    };

    return (
        <ErrorCatcher componentName="BlogArchive">
            <div>
                <Show when={postsContent()} fallback={<div class={tagClasses('', searchParams.category)}></div>}>
                    <div class="mb-l tablet:mt-xxl gap-x-xxs flex flex-wrap">
                        <button aria-label={'Filtrera på alla'} onClick={() => handleFilterClick(undefined)} class={tagClasses('', searchParams.category)}>
                            Alla
                        </button>
                        <For each={blogCategoriesFromHook}>
                            {(category: BlogCategory) => (
                                <button
                                    innerHTML={category.name}
                                    aria-label={`Filtrera på ${category.name}`}
                                    onClick={() => handleFilterClick(category.slug)}
                                    class={tagClasses(category.slug, searchParams.category)}
                                >
                                    {category.name}
                                </button>
                            )}
                        </For>
                    </div>
                    <div class="tablet:grid-cols-2 laptop:grid-cols-3 gap-y-xl gap-x-l tablet:gap-y-xxl tablet:gap-x-xxl mb-xl grid grid-cols-1">
                        <For each={postsContent().posts}>{(post) => <BlogListItem post={post.content} />}</For>
                    </div>
                </Show>
                <Show when={postsContent()?.posts?.length === totalAmount()}>
                    <div class="text-center">
                        <Button variant="solid" color={ButtonColorName.Primary} onClick={handleLoadMoreClick} label="Ladda fler" buttonSize="small" />
                    </div>
                </Show>
            </div>
        </ErrorCatcher>
    );
};
