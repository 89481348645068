import clsx from 'clsx';
import { Show, useContext } from 'solid-js';
import tailwindConfig from '../../../tailwind.config';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { QuotationIcon } from '../../components/icons-library/quotation';
import { WrappedImage } from '../../components/image-component/wrapped-image';
import { ColorName, bgColorClasses, fillColorClasses, textColorClasses } from '../../style/color-classes';
import { TestimonyProps } from './testimony-types';

export const Testimony = (props: TestimonyProps) => {
    const { viewport } = useContext(AppContext);

    const isTablet = () => viewport.width <= tailwindConfig.theme.extend.screenValues.tabletValue;

    const textColorClass = props.variant === 'dark' ? textColorClasses[ColorName.White] : textColorClasses[ColorName.Secondary700];
    const bgColorClass = props.variant === 'dark' ? bgColorClasses[ColorName.Secondary600] : bgColorClasses[ColorName.White];
    const fillColorClass = fillColorClasses[ColorName.Primary];

    return (
        <blockquote
            class={clsx(
                textColorClass,
                bgColorClass,
                'tablet:pb-12 tablet:pl-24 tablet:pr-12 tablet:pt-20 relative mt-10 flex max-w-fit flex-col rounded-l px-6 pb-8 pt-12',
            )}
        >
            <figure class="tablet:top-m tablet:-left-8 tablet:transform-none absolute -top-6 left-1/2 -translate-x-1/2 transform">
                <QuotationIcon width={isTablet() ? 50 : 95} height={isTablet() ? 39 : 73} class={clsx(fillColorClass)} />
            </figure>
            <p class="text-testimonyContent tablet:pb-6 pb-8" innerHTML={props.quote}></p>
            <footer class="tablet:gap-s gap-xs tablet:flex-row tablet:mx-0 tablet:text-left mx-auto flex flex-col text-center">
                <Show when={props.image}>
                    <div class="tablet:h-[119px] tablet:w-[119px] tablet:mx-0 mx-auto h-20 w-20">
                        <WrappedImage image={props.image} aspectRatio="square" roundCorners="full" width="200px" height="auto" />
                    </div>
                </Show>
                <div class="flex flex-col justify-center">
                    <span class="text-testimonyAuthor text-primary capitalize">{props.authorName}</span>
                    <cite class="text-testimonyAuthorDescription not-italic">{props.authorShortDescription}</cite>
                </div>
            </footer>
        </blockquote>
    );
};

Testimony.parseProps = (atts: any) => {
    const { quote, authorName, authorShortDescription, image, variant } = atts;

    const commonAtss = {
        quote,
        authorName,
        authorShortDescription,
        image,
        variant,
    };

    return commonAtss;
};
