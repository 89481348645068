import clsx from 'clsx';
import { mergeProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { ColorName, textColorClasses } from '../../style/color-classes';
import { ErrorCatcher } from '../../tools/error-catcher';
import { HeadingProps } from './heading.d';

const defaultProps: HeadingProps = {
    tag: 'h2',
    children: '',
    disableMargins: true,
    classes: '',
    disableMaxWidth: false,
};

export const Heading = (componentProps: HeadingProps) => {
    const props = mergeProps(defaultProps, componentProps);

    const alignClass = () =>
        clsx({ 'text-left': props.align === 'left' }, { 'text-center': props.align === 'center' }, { 'text-right': props.align === 'right' });

    let sizeClass;

    if (props.fontSize !== 'default') {
        sizeClass = () =>
            clsx(
                { 'text-headerS': props.fontSize === 's' },
                { 'text-headerM': props.fontSize === 'm' },
                { 'text-headerL': props.fontSize === 'l' },
                { 'text-headerXL': props.fontSize === 'xl' },
                { 'text-headerXXL': props.fontSize === 'xxl' },
                { 'text-headerXXXL': props.fontSize === 'xxxl' },
            );
    } else {
        sizeClass = () =>
            clsx(
                { 'text-headerS': props.tag === 'h5' },
                { 'text-headerM': props.tag === 'h4' },
                { 'text-headerL': props.tag === 'h3' },
                { 'text-headerXL': props.tag === 'h2' },
                { 'text-headerXXL': props.tag === 'h1' },
            );
    }

    const colorClassName = () => (props.colorName ? textColorClasses[props.colorName as ColorName] : '');

    return (
        <ErrorCatcher componentName="Heading">
            <Dynamic
                class={clsx(
                    'w-full',
                    { 'max-w-textMaxWidth': !props.disableMaxWidth && props.tag !== 'h1' },
                    { 'max-w-heading1MaxWidth': !props.disableMaxWidth && props.tag === 'h1' },
                    alignClass(),
                    sizeClass(),
                    colorClassName(),
                    'font-paytone',
                    { 'my-[0.5em]': !props.disableMargins },
                    props.classes,
                )}
                component={props.tag}
            >
                {props.children}
            </Dynamic>
        </ErrorCatcher>
    );
};

Heading.parseProps = (atts: any) => {
    /* 
        we are not able to change it in Gutenberg, but most headings had default value of none.
        to avoid syncing all pages i just remove it here for now
    */
    delete atts.textTransform;
    return {
        ...atts,
        tag: atts.tag,
        colorName: atts.color?.name,
        align: atts.align,
        fontSize: atts.size ? atts.size : 'default',
        disableMargins: false,
        disableMaxWidth: false,
    };
};
