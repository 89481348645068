type ExtraSettings = {
    translation: {
        value: string
    },
    info: {
        value: string
    },
    link: {
        value: string
    },
    linkText: {
        value: string
    },
    placeholder: {
        value: string
    }
    
};

export type FieldType = {
    labelIdleClasses: string;
    labelFocusClasses: string;
    inputClasses: string;
    name: string;
    slug: string;
    label: string;
    alias?: string;
    required?: boolean;
    isRequired?: boolean;
    type?: string;
    requiredInForm?: boolean;
    extraSettings?: RxtraSettings;
    instance?: number;
    handleInputChange: (fieldSlug: string, fieldValue:any) => void;
    formValues: Accessor<any>;
};
export type FormProps = {
    variant: string;
    form: MauticForm,
    fields: FieldType[],
    submitButtonText: string;
    submitSuccessText: string;
    submitErrorText: string;
};

export type MauticForm = {
    name: string;
    id: string;
    return: string;
    messenger: string;
    postAction: PostAction;
    postActionProperty: string;
    fields: FieldType[];
};

// TODO: Object with {key: string}
export type FormValue = any;

export type DropdownOption = {
    value: string;
    text: string;
};

export enum MauticPostAction {
    Message = 'message',
    Redirect = 'redirect',
    StayOnForm = 'return',
}
