import { ColorName } from './color-classes';

export type ColorObject = {
    name: ColorName;
    color?: string;
};

export type HorizontalAlignment = 'left' | 'center' | 'right';
export enum VerticalAlignment {
    top = 'top',
    middle = 'middle',
    bottom = 'bottom',
    spread = 'spread',
}

export const breakpoints = {
    mobile: 480,
    tablet: 768,
    desktop: 1440,
    desktopXL: 1920,
};
