import clsx from 'clsx';
import { gql } from 'graphql-request';
import { For, Show, createEffect, createSignal, useContext } from 'solid-js';
import tailwindConfig from '../../../tailwind.config';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { NewsListItem } from './news-list-item';

type NewsArchiveProps = {
    limit?: number;
    offset?: number;
};

const GET_NEWS_QUERY = gql`
    query GetNewsPosts($type: [String]) {
        news: resources(type: $type, order: DESC) {
            content
        }
    }
`;

export const NewsArchive = (componentProps: NewsArchiveProps) => {
    const { createCachedResource, viewport } = useContext(AppContext);

    const [totalAmount, setTotalAmount] = createSignal(1);

    const [queryVars, setQueryVars] = createSignal<any>({
        tags: undefined,
        limit: totalAmount(),
        offset: 4,
    });
    const [isMobile, setIsMobile] = createSignal(viewport.width <= tailwindConfig.theme.extend.screenValues.tabletValue);
    const [isTablet, setIsTablet] = createSignal(viewport.width >= tailwindConfig.theme.extend.screenValues.tabletValue);
    createEffect(() => {
        setIsMobile(viewport.width <= tailwindConfig.theme.extend.screenValues.tabletValue);
        setIsTablet(viewport.width >= tailwindConfig.theme.extend.screenValues.tabletValue);
    });
    const [newsContent] = createCachedResource(GET_NEWS_QUERY, () => ({ type: ['news'] }), true);

    const handleLoadMoreClick = () => {
        setTotalAmount(totalAmount() + 20);
        setQueryVars({
            tags: undefined,
            limit: totalAmount(),
            offset: 0,
        });
    };

    const loadingClasses = clsx('min-h-[200rem]');
    return (
        <ErrorCatcher componentName="NewsArchive">
            <div>
                <Show when={newsContent()} fallback={<div class={loadingClasses}></div>}>
                    <div class="tablet:grid-cols-1 desktop:grid-cols-1 gap-y-xl gap-x-l tablet:gap-y-xxl tablet:gap-x-xxl mb-xl mt-20 grid grid-cols-1">
                        <For each={newsContent().news}>
                            {(news) => {
                                return <NewsListItem news={news.content} isMobile={isMobile()} isTablet={isTablet()} />;
                            }}
                        </For>
                    </div>
                </Show>
                <Show when={newsContent()?.posts?.length === 1}>
                    <button aria-label="Ladda fler nyheter" class="bg-primary px-8 py-4" onClick={handleLoadMoreClick}>
                        Load more (unstyled)
                    </button>
                </Show>
            </div>
        </ErrorCatcher>
    );
};
