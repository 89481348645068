import { For } from 'solid-js';
import { ImageWordpress } from '../../types/shared';
import { ProfilePhotoCollageImage } from './profile-photo-collage-image';

export const ProfilePhotoCollage = (props: ProfilePhotoCollageProps) => {
    // Below we use slice to limit the number of images to 5. This is because the collage is designed to only show 5 images right now.
    // I did not want to create a limiting functionality in WP as it was not super quick and I know that we will expand on this
    // later to be able to either randomize what 5 images of a collection is shown or to show all images in a collection and have
    // the design adapt accordingly.
    return (
        <div class="relative z-20 h-[360px]">
            <For each={props.profilePhotos.slice(0, 5)}>
                {(profilePhoto: ProfilePhoto, index) => <ProfilePhotoCollageImage image={profilePhoto.image} index={index()} />}
            </For>
        </div>
    );
};

type ProfilePhoto = {
    image: ImageWordpress;
};
export type ProfilePhotoCollageProps = {
    profilePhotos: ProfilePhoto[];
};
