import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    stroke: 'none',
    fill: 'currentColor',
};

export const CopyIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <g clip-path="url(#a)"><path d="M15 5H8c-.6 0-1 .4-1 1v9c0 .6.4 1 1 1h7c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1Z" /><path d="M5 4c0-.6.4-1 1-1h6V1c0-.6-.4-1-1-1H1C.4 0 0 .4 0 1v12c0 .6.4 1 1 1h4V4Z" /></g><defs><clipPath id="a"><path d="M0 0h16v16H0z" /></clipPath></defs>
        </SvgIcon>
        
    );
};


