import { ErrorBoundary } from 'solid-js';
// import { styled } from 'solid-styled-components';

type ErrorCatcherProps = {
    componentName: string;
    children: any;
};


// const StyledErrorCatcher = styled.div`
//     z-index: 100;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     background-color: lightcoral;
//     color: white;
//     padding: 1rem;
// `;

export const ErrorCatcher = (props: ErrorCatcherProps) => {
    return (
        <ErrorBoundary fallback={(err: Error) => {
            const message = `Error caught in ${props.componentName}`;
    
            console.log(message, err);
            
            return (
                <>
                    {message}: {err?.toString()}
                </>
            );
        }}>
            {props.children}
        </ErrorBoundary>
    );
};