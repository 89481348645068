import { getYoutubeVideoId } from './video-helpers';

export const VideoEmbed = (props: VideoEmbedProps) => {
    const videoId = getYoutubeVideoId(props.url);

    return (
        <div class="aspect-video w-full overflow-hidden rounded-l">
            <iframe
                src={`https://www.youtube.com/embed/${videoId}?controls=${+props?.controls}&showinfo=0&start=${props.startTime}${
                    props.autoplay && '&autoplay=1&mute=1'
                }`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                class="aspect-video w-full rounded-l"
                color="white"
            ></iframe>
        </div>
    );
};

export type VideoEmbedProps = {
    url: '';
    controls: boolean;
    startTime: number;
    autoplay: boolean;
};
