export type ResponsiveClasses = {
    mobile: ResponsiveOverrideScreen;
    tablet: ResponsiveOverrideScreen;
    desktop: ResponsiveOverrideScreen;
    desktopXL: ResponsiveOverrideScreen;
};

export type ResponsiveOverrideLocation = {
    '-': string;
    s: string;
    m: string;
    l: string;
    xl: string;
    xxl: string;
};

export type ResponsiveOverrideScreen = {
    marginTop: ResponsiveOverrideLocation;
    marginBottom: ResponsiveOverrideLocation;
    paddingTop: ResponsiveOverrideLocation;
    paddingBottom: ResponsiveOverrideLocation;
};

export const responsiveClasses = {
    mobile: {
        marginTop: {
            '-': 'max-tablet:mt-0',
            s: 'max-tablet:mt-s',
            m: 'max-tablet:mt-m',
            l: 'max-tablet:mt-l',
            xl: 'max-tablet:mt-xl',
            xxl: 'max-tablet:mt-xxl',
        },
        marginBottom: {
            '-': 'max-tablet:mb-0',
            s: 'max-tablet:mb-s',
            m: 'max-tablet:mb-m',
            l: 'max-tablet:mb-l',
            xl: 'max-tablet:mb-xl',
            xxl: 'max-tablet:mb-xxl',
        },
        paddingTop: {
            '-': 'max-tablet:pt-0',
            s: 'max-tablet:pt-s',
            m: 'max-tablet:pt-m',
            l: 'max-tablet:pt-l',
            xl: 'max-tablet:pt-xl',
            xxl: 'max-tablet:pt-xxl',
        },
        paddingBottom: {
            '-': 'max-tablet:pb-0',
            s: 'max-tablet:pb-s',
            m: 'max-tablet:pb-m',
            l: 'max-tablet:pb-l',
            xl: 'max-tablet:pb-xl',
            xxl: 'max-tablet:pb-xxl',
        },
    },
    tablet: {
        marginTop: {
            '-': 'tablet:max-desktop:mt-0',
            s: 'tablet:max-desktop:mt-s',
            m: 'tablet:max-desktop:mt-m',
            l: 'tablet:max-desktop:mt-l',
            xl: 'tablet:max-desktop:mt-xl',
            xxl: 'tablet:max-desktop:mt-xxl',
        },
        marginBottom: {
            '-': 'tablet:max-desktop:mb-0',
            s: 'tablet:max-desktop:mb-s',
            m: 'tablet:max-desktop:mb-m',
            l: 'tablet:max-desktop:mb-l',
            xl: 'tablet:max-desktop:mb-xl',
            xxl: 'tablet:max-desktop:mb-xxl',
        },
        paddingTop: {
            '-': 'tablet:max-desktop:pt-0',
            s: 'tablet:max-desktop:pt-s',
            m: 'tablet:max-desktop:pt-m',
            l: 'tablet:max-desktop:pt-l',
            xl: 'tablet:max-desktop:pt-xl',
            xxl: 'tablet:max-desktop:pt-xxl',
        },
        paddingBottom: {
            '-': 'tablet:max-desktop:pb-0',
            s: 'tablet:max-desktop:pb-s',
            m: 'tablet:max-desktop:pb-m',
            l: 'tablet:max-desktop:pb-l',
            xl: 'tablet:max-desktop:pb-xl',
            xxl: 'tablet:max-desktop:pb-xxl',
        },
    },
    desktop: {
        marginTop: {
            '-': 'desktop:max-desktopXL:mt-0',
            s: 'desktop:max-desktopXL:mt-s',
            m: 'desktop:max-desktopXL:mt-m',
            l: 'desktop:max-desktopXL:mt-l',
            xl: 'desktop:max-desktopXL:mt-xl',
            xxl: 'desktop:max-desktopXL:mt-xxl',
        },
        marginBottom: {
            '-': 'desktop:max-desktopXL:mb-0',
            s: 'desktop:max-desktopXL:mb-s',
            m: 'desktop:max-desktopXL:mb-m',
            l: 'desktop:max-desktopXL:mb-l',
            xl: 'desktop:max-desktopXL:mb-xl',
            xxl: 'desktop:max-desktopXL:mb-xxl',
        },
        paddingTop: {
            '-': 'desktop:max-desktopXL:pt-0',
            s: 'desktop:max-desktopXL:pt-s',
            m: 'desktop:max-desktopXL:pt-m',
            l: 'desktop:max-desktopXL:pt-l',
            xl: 'desktop:max-desktopXL:pt-xl',
            xxl: 'desktop:max-desktopXL:pt-xxl',
        },
        paddingBottom: {
            '-': 'desktop:max-desktopXL:pb-0',
            s: 'desktop:max-desktopXL:pb-s',
            m: 'desktop:max-desktopXL:pb-m',
            l: 'desktop:max-desktopXL:pb-l',
            xl: 'desktop:max-desktopXL:pb-xl',
            xxl: 'desktop:max-desktopXL:pb-xxl',
        },
    },
    desktopXL: {
        // You have to write them like this otherwise overrides will not work
        // i.e you CAN'T write 'desktopXL:mt-0' or 'min-desktopXL:mt-0' because it would not override default section margins / paddings
        // dont ask why.
        marginTop: {
            '-': 'min-[1920px]:mt-0',
            s: 'min-[1920px]:mt-s',
            m: 'min-[1920px]:mt-m',
            l: 'min-[1920px]:mt-l',
            xl: 'min-[1920px]:mt-xl',
            xxl: 'min-[1920px]:mt-xxl',
        },
        marginBottom: {
            '-': 'min-[1920px]:mb-0',
            s: 'min-[1920px]:mb-s',
            m: 'min-[1920px]:mb-m',
            l: 'min-[1920px]:mb-l',
            xl: 'min-[1920px]:mb-xl',
            xxl: 'min-[1920px]:mb-xxl',
        },
        paddingTop: {
            '-': 'min-[1920px]:pt-0',
            s: 'min-[1920px]:pt-s',
            m: 'min-[1920px]:pt-m',
            l: 'min-[1920px]:pt-l',
            xl: 'min-[1920px]:pt-xl',
            xxl: 'min-[1920px]:pt-xxl',
        },
        paddingBottom: {
            '-': 'min-[1920px]:pb-0',
            s: 'min-[1920px]:pb-s',
            m: 'min-[1920px]:pb-m',
            l: 'min-[1920px]:pb-l',
            xl: 'min-[1920px]:pb-xl',
            xxl: 'min-[1920px]:pb-xxl',
        },
    },
};
