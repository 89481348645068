import { Text } from '../../text/text';
import { ErrorCatcher } from '../../../tools/error-catcher';
import clsx from 'clsx';
import { createEffect } from 'solid-js';
import { ColorName } from '../../../style/color-classes';

type CheckboxProps = {
    whenClicked: () => void;
    value: string;
    isChecked: boolean;
    name: string;
};

export const Checkbox = (props: CheckboxProps) => {
    const checkboxClasses = () =>
        clsx(
            'cursor-pointer',
            'flex',
            'gap-1',
            'relative',
            'pl-8',
            'before:content-normal',
            'before:absolute',
            'before:w-4',
            'before:h-4',
            'before:bg-blackTransparent-20',
            'before:rounded-xs',
            'before:left-0',
            'before:p-3',
            'hover:before:bg-blackTransparent-50',
            { 'after:content-check': props.isChecked },
            'after:absolute',
            'after:w-4',
            'after:h-4',
            'after:rounded-xs',
            'after:top-0.5',
            'after:left-0',
            'after:translate-x-1',
        );

    return (
        <ErrorCatcher componentName="Checkbox">
            <label onInput={props.whenClicked} for={props.value} class={checkboxClasses()}>
                <input id={props.value} type="checkbox" value={props.value} checked={props.isChecked} class="hidden" />
                <div />
                <Text fontSize="xs" color={ColorName.Secondary300}>
                    {props.name}
                </Text>
            </label>
        </ErrorCatcher>
    );
};
