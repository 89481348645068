import { ContactCardType } from '../contact-cards';
import { ChatBubbleArrowIcon } from '../../icons-library/chat-bubble-arrow';
import { ErrorCatcher } from '../../../tools/error-catcher';
import clsx from 'clsx';
import { WrappedImage } from '../../image-component/wrapped-image';

type Props = {
    contactCard: ContactCardType;
    cardsAmount: number;
    currentCardNumber: number;
    displayChatBubble: string;
};

export const ContactCard = (props: Props) => {
    return (
        <ErrorCatcher componentName="ContactCard">
            <div
                class={clsx(
                    'flex-col',
                    'justify-center',
                    'text-center',
                    'space-y-s',
                    'last:mb-0',
                    'mb-xl',
                    'max-w-[21rem]',
                    'mx-auto',
                    'tablet:mx-[unset]',
                    { 'group text-center': props.displayChatBubble === 'onHover' },
                    { 'w-full': props.cardsAmount >= 3 },
                    { 'laptop:w-1/3 w-full': props.cardsAmount <= 2 },
                )}
            >
                <div class="relative">
                    <div class="w-full">
                        <WrappedImage image={props.contactCard.image} aspectRatio="square" roundCorners="l" width="200px" height="auto" />
                    </div>

                    {props.contactCard.quote ? (
                        <div
                            class={clsx(
                                'bg-secondary-600',
                                'drop-shadow-speechBubble',
                                'font-paytone',
                                'text-paragraphM',
                                'absolute',
                                { 'hidden group-hover:block': props.displayChatBubble === 'onHover' },
                                { 'tablet:block hidden': props.displayChatBubble === 'onDesktop' },
                                props.contactCard.quotePosition === 'left' ? 'laptop:-left-[25%] left-0' : 'laptop:-right-[7rem] right-0',
                                props.contactCard.quotePosition === 'left' ? 'top-[-12%]' : 'tablet:bottom-0 bottom-[90%]',
                                'z-10',
                                'w-[15rem]',
                                'rounded-s',
                                'p-6',
                                'text-white',
                            )}
                        >
                            <blockquote>{props.contactCard.quote}</blockquote>
                            <div
                                class={clsx(
                                    'absolute',
                                    props.contactCard.quotePosition === 'left' ? 'left-[50%]' : 'left-[25%]',
                                    props.contactCard.quotePosition === 'left' ? 'top-[100%]' : 'tablet:top-[-28px] top-[100%]',
                                    '-translate-y-px',
                                    props.contactCard.quotePosition === 'left' ? '' : 'tablet:rotate-180 tablet:scale-x-100 scale-x-[-1]',
                                )}
                            >
                                <ChatBubbleArrowIcon width={34} height={31} class="fill-secondary-600" />
                            </div>
                        </div>
                    ) : null}
                </div>
                <div>
                    <h3
                        class="
                        font-paytone
                        text-headerM
                    "
                    >
                        {props.contactCard.name}
                    </h3>
                    {props.contactCard.title ? (
                        <h4 class="text-secondary-300 text-paragraphM my-4">{props.contactCard.title}</h4>
                    ) : null}
                    {props.contactCard.phoneNumber || props.contactCard.email ? (
                        <address class="text-secondary-300 text-paragraphM">
                            <ul>
                                {props.contactCard.phoneNumber ? (
                                    <li>
                                        <a href={'tel:' + props.contactCard.phoneNumber}>{props.contactCard.phoneNumber}</a>
                                    </li>
                                ) : null}
                                {props.contactCard.email ? (
                                    <li>
                                        <a href={'mailto:' + props.contactCard.email}>{props.contactCard.email}</a>
                                    </li>
                                ) : null}
                            </ul>
                        </address>
                    ) : null}
                </div>
            </div>
        </ErrorCatcher>
    );
};
