import clsx from 'clsx';
import { createEffect, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { breakpoints } from '../../style/theme';
import { ImageWordpress } from '../../types/shared';
import { WrappedImage } from '../image-component/wrapped-image';

export const ProfilePhotoCollageImage = (props: ProfilePhotoCollageImageProps) => {
    const { viewport } = useContext(AppContext);
    const [scrollY, setScrollY] = createSignal(0);

    let containerRef: HTMLDivElement;

    const updateScrollY = () => {
        setScrollY(window.scrollY);
    };

    createEffect(() => {
        window.addEventListener('scroll', updateScrollY);
        return () => {
            window.removeEventListener('scroll', updateScrollY);
        };
    });

    const sizeClasses = [
        'w-[20%] tablet:w-[30%] bigTablet:w-[30%] desktop:w-[20%]',
        'w-[25%] bigTablet:w-[30%] desktop:w-[25%]',
        'w-[60%] tablet:w-[50%] bigTablet:w-[60%] desktop:w-[30%]',
        'w-[35%] bigTablet:w-[40%] desktop:w-[35%]',
        'w-[45%] bigTablet:w-[50%] desktop:w-[40%]',
    ];

    const positionClasses = [
        'top-[10%] right-[-10%] laptop:right-[-10%] desktop:right-[-5%]',
        'top-[35%] tablet:top-[40%] laptop:top-[55%] right-0 laptop-[-10%]',
        'top-[45%] bigTablet:top-[60%] desktop:top-[70%] right-[20%]',
        'top-[30%] right-[55%] laptop:right-[65%] desktop:right-[50%]',
        'top-[0] laptop:top-[-10%] desktopXL:top-[-20%] right-[12%]',
    ];

    const scrollSpeeds = [0.01, 0.02, 0.03, 0.04, 0.05];

    const scrollDirections = [1, -1, -1, -1, -1];

    const photoCollageImageClasses = clsx(
        'shadow-speechBubble',
        'absolute',
        'rounded-full',
        'absolute',
        'transition-all',
        'duration-1000',
        'bg-primary-100',
        sizeClasses[props.index],
        positionClasses[props.index],
    );

    const parallaxOffset = () => {
        const container = containerRef;
        if (container) {
            const containerRect = container.getBoundingClientRect();
            const containerScrollY = scrollY() - containerRect.top;
            return containerScrollY * scrollDirections[props.index] * scrollSpeeds[props.index];
        }
        return 0;
    };

    const parallaxPosition = () => {
        // ignore parallax on mobile
        if (viewport.width <= breakpoints.tablet) return {};
        return {
            transform: `translateY(${parallaxOffset()}px)`,
            'will-change': 'transform',
        };
    };

    return (
        <div ref={containerRef} class={photoCollageImageClasses} style={{ ...parallaxPosition() }}>
            <WrappedImage image={props.image} roundCorners="full" aspectRatio="square" renderWidth="100%" renderHeight="auto" width="450px" height="auto" />
        </div>
    );
};

export type ProfilePhotoCollageImageProps = {
    index: number;
    image: ImageWordpress;
};
