import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    height: 24,
    width: 24,
    fill: 'currentColor',
    viewBox: '0 0 24 24',
};

export const PlusIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
        </SvgIcon>
    );
};
