import { A, Link } from '@solidjs/router';
import clsx from 'clsx';
import { Match, Switch, createSignal } from 'solid-js';
import { ExpandingContainer } from '../../../ui-components/expanding-container/expanding-container';
import { ChevronUpBoldIcon } from '../../icons-library/chevron-up-bold';
import { MenuItem } from '../menu-types';
import { MobileSlideSubMenu } from './mobile-slide-sub-menu';

export const MobileSlideMenuItem = (props: MobileSlideMenuItemProps) => {
    const [isExpanded, setIsExpanded] = createSignal(false);

    return (
        <div data-identifier="mobile-slide-menu-item">
            <div class="font-poppinsRegular text-mobileMenu tablet:text-tabletMenu font-bold">
                <Switch
                    fallback={
                        <A class="px-6 py-4 inline-block" href={props.item.url}>
                            {props.item.title}
                        </A>
                    }
                >
                    <Match when={props.item.children}>
                        <button
                            aria-label={(isExpanded() ? 'Dölj' : 'Visa') + ' undermeny för ' + props.item.title}
                            class="flex items-center px-6 py-4"
                            onClick={() => setIsExpanded(!isExpanded())}
                        >
                            {props.item.title}
                            <span class={clsx('ml-2 tablet:ml-4 w-4 h-4 tablet:w-6 tablet:h-6 transform transition-transform origin-center duration-500', { '-rotate-180': isExpanded() })}>
                                <ChevronUpBoldIcon width="100%" height="100%" class="stroke-secondary-600" />
                            </span>
                        </button>
                        <ExpandingContainer open={isExpanded()}>
                            <MobileSlideSubMenu menuItems={props.item.children} />
                        </ExpandingContainer>
                    </Match>
                </Switch>
            </div>
        </div>
    );
};
type MobileSlideMenuItemProps = {
    item: MenuItem;
};
