import clsx from 'clsx';
import { TagProps } from './tag-types';

export const Tag = (props: TagProps) => {
    const classes = clsx('inline-block rounded-xl px-2 text-[11px] font-bold uppercase text-white max-w-[fit-content]', {
        'bg-[#F15A22]': !props.color,
        [`${props.color}`]: props.color,
    });
    return <div class={classes}>{props.text}</div>;
};
