import { useSearchParams } from '@solidjs/router';
import clsx from 'clsx';
import { gql } from 'graphql-request';
import { For, Show, createSignal, mergeProps, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ButtonColorName } from '../../style/color-classes';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Button } from '../../ui-components/button/button';
import { ProjectListItem } from './project-list-item';

export type PostContainer = {
    content: any;
};

export type Category = {
    name: string;
    slug: string;
    id?: number;
};

export type Categories = {
    categories: Category[];
};

const defaultProps: BlogArchiveProps = {
    limit: 20, // Change this to a higher value in the future, but while developing, it is nice to have a low number. Also: maybe get this value from Wordpress in a setting on the gutenberg block
    offset: 0,
    categories: [],
};

const GET_PROJECTS_QUERY = gql`
    query GetProjects($tags: [String], $limit: Int, $offset: Int) {
        posts: resources(type: "project", tags: $tags, limit: $limit, offset: $offset, order: DESC) {
            content
        }
    }
`;

export type BlogArchiveProps = {
    tag?: {
        slug: string;
        name?: string;
    };
    limit?: number;
    offset?: number;
    projectCategoriesFromHook: Array<any>;
};

export const ProjectArchive = (componentProps: BlogArchiveProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamsCategory = searchParams.category;
    const props = mergeProps(defaultProps, componentProps);
    const initialLimit = props.limit ? props.limit : 1;
    const [selectedFilter, setSelectedFilter] = createSignal<string>();

    const { createCachedResource } = useContext(AppContext);

    const [totalAmount, setTotalAmount] = createSignal(initialLimit);
    const [queryVars, setQueryVars] = createSignal<any>({
        tags: searchParamsCategory || selectedFilter() || undefined,
        limit: totalAmount(),
        offset: props.offset,
    });

    const [postsContent] = createCachedResource(GET_PROJECTS_QUERY, queryVars, true);

    const handleLoadMoreClick = () => {
        setTotalAmount(totalAmount() + initialLimit);
        setQueryVars({
            tags: searchParamsCategory || selectedFilter() || undefined,
            limit: totalAmount(),
            offset: 0,
        });
    };

    const handleFilterClick = (slug: string) => {
        setSearchParams({ category: slug });
        setSelectedFilter(slug);
        setQueryVars({
            tags: selectedFilter() || undefined,
            limit: initialLimit,
            offset: 0,
        });
    };

    const setTagClasses = (slug: string) => {
        const tagClasses = clsx(
            'border-1',
            'mb-xxs',
            'border-secondary-600',
            'px-s',
            'text-secondary-600',
            'hover:bg-secondary-600',
            'hover:text-white',
            'text-medium',
            'inline-block',
            'rounded-l',
            'py-[0.31rem]',
            'transition-colors',
            'duration-200',
            { 'bg-secondary-600': slug === searchParams.category },
            { '!text-white': slug === searchParams.category },
        );
        return tagClasses;
    };
    const loadingClassses = clsx('min-h-[200rem]');
    return (
        <ErrorCatcher componentName="ProjectArchive">
            <div>
                <Show when={postsContent()} fallback={<div class={loadingClassses}></div>}>
                    <div class="mb-l tablet:mt-xxl gap-x-xxs flex flex-wrap">
                        <Show when={props.projectCategoriesFromHook}>
                            <For each={props.projectCategoriesFromHook}>
                                {(category: any) => (
                                    <button aria-label={category.name} onClick={() => handleFilterClick(category.slug)} class={setTagClasses(category.slug)}>
                                        {category.name}
                                    </button>
                                )}
                            </For>
                        </Show>
                    </div>
                    <div class="tablet:grid-cols-2 laptop:grid-cols-3 desktopXL:grid-cols-4 gap-y-xl gap-x-l tablet:gap-y-xxl tablet:gap-x-xxl mb-xl grid grid-cols-1">
                        <For each={postsContent().posts}>
                            {(post) => {
                                return <ProjectListItem post={post.content} />;
                            }}
                        </For>
                    </div>
                </Show>
                <Show when={postsContent()?.posts?.length === totalAmount()}>
                    <div class="text-center">
                        <Button variant="solid" color={ButtonColorName.Primary} onClick={() => handleLoadMoreClick()} label="Ladda fler" buttonSize="small" />
                    </div>
                </Show>
            </div>
        </ErrorCatcher>
    );
};
