import { A } from '@solidjs/router';
import clsx from 'clsx';
import { ErrorCatcher } from '../../tools/error-catcher';
import { WrappedImage } from '../image-component/wrapped-image';

export const BlogListItem = (props: any) => {
    const duoToneColorClasses = ['duotone-orange', 'duotone-pink', 'duotone-blue'];
    const randomDuoToneColorClass: string = duoToneColorClasses[Math.floor(Math.random() * duoToneColorClasses.length)];

    return (
        <ErrorCatcher componentName="BlogListItem">
            <A class="group" href={props.post.permalink}>
                <div>
                    <div class={clsx('mb-s', { 'tablet:mb-[1.25rem]': props.readMore })}>
                        <div class={clsx(randomDuoToneColorClass, 'group-hover:blur-none', 'group-focus:blur-none')}>
                            {props.post.featured_image ? (
                                <WrappedImage width={300} renderWidth={'100%'} height={'auto'} aspectRatio={'wide'} image={props.post.featured_image} />
                            ) : (
                                <div class="aspect-video w-[300px]" />
                            )}
                        </div>
                    </div>
                    <h3 class={clsx('mb-[0.25em]', 'text-headerL', 'font-paytone', 'w-full', 'max-w-textMaxWidth', { 'tablet:text-headerS': props.readMore })}>
                        {props.post.post_title}
                    </h3>
                    {props.post.list_description && (
                        <p
                            class={clsx('w-full', 'max-w-textMaxWidth', 'text-left', 'text-paragraphM', 'mb-[1em]', {
                                'tablet:text-paragraphS': props.readMore,
                            })}
                        >
                            {props.post.list_description}
                        </p>
                    )}
                </div>
            </A>
        </ErrorCatcher>
    );
};

export type BlogListItemProps = {
    post: {
        featured_image: string;
        permalink: string;
        post_title: string;
        list_description: string;
    };
};
