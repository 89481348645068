import { createEffect, Show, useContext } from 'solid-js';
import { Link, Meta, Title } from 'solid-meta';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { getBackgroundImageSrcData, ImageSrcData } from '../image-component/image-component';
import { Seo } from './seo-settings-types';

type SeoSettingsProps = {
    seo?: () => Seo;
    title: string;
    currentUrl?: () => string;
};

export const SeoSettings = (props: SeoSettingsProps) => {

    const { siteInfo } = useContext(AppContext);

    const seo = () => {
        if (props.seo) {
            return props.seo();
        }
        return null;
    };

    const getCanonicalUrl = () => {
        if (seo()?.useCanonicalUrl && seo()?.canonicalUrl) {
            let url = seo().canonicalUrl;
            if (!url.match(/^http(s|)?:\/\//)) {
                url = siteInfo.baseurl + url.replace(/\/$/, '');
            }
            return url;
        }
        if (!props.currentUrl) {
            return null;
        }
        let url = props.currentUrl();
        url = url.replace(/\/$/, '');
        return siteInfo.baseurl + (url || '/').replace(/\/$/, '');
    };

    const customPageTitle = () => {
        if (seo() && seo()?.useCustomPageTitle && seo()?.pageTitle) {
            return `${seo()?.pageTitle} - TwoFour`;
        } else {
            return `${props.title} - TwoFour`;
        }
    };

    createEffect(() => {
        document.title = customPageTitle();

        // Dont understand why we need to do this. It should be updated automatically. We really dotn need to do this
        // but it looks better.
        if (getCanonicalUrl()) {
            document.querySelector('link[rel="canonical"]')?.setAttribute('href', getCanonicalUrl());
        }
    });

    const { imagesServiceUrl, supportedImageFormats } = useContext(AppContext);
    let backgroundImageSrcData = null;

    if (seo() && seo()?.use_og_image && seo()?.og_image) {
        const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
            imagesServiceUrl,
            supportedImageFormats,
            src: seo()?.og_image || '',
            width: 1200,
            height: 'AUTO',
        });

        backgroundImageSrcData = backgroundImage;
    }

    return (
        <ErrorCatcher componentName="SEO settings">
            <Title>{customPageTitle()}</Title>
            <Show when={seo()?.useMetaDescription && seo()?.metaDescription}>
                <Meta name="description" content={seo()?.metaDescription} />
                <Meta name="og:description" content={seo()?.metaDescription} />
            </Show>
            <Show
                when={seo() && seo()?.use_og_title && seo()?.og_title}
                fallback={<Meta name="title" property="og:title" content={customPageTitle()} />}
            >
                <Meta name="twitter:title" content={seo()?.og_title} />
                <Meta name="title" property="og:title" content={seo()?.og_title} />
            </Show>
            <Show when={seo() && seo()?.use_og_description && seo()?.og_description}>
                <Meta name="og:description" content={seo()?.og_description} />
                <Meta name="twitter:description" content={seo()?.og_description} />
            </Show>
            <Show when={getCanonicalUrl()}>
                <Link rel="canonical" href={getCanonicalUrl()} />
            </Show>
            <Show when={seo() && seo()?.use_og_image && seo()?.og_image}>
                <Meta name="image" property="og:image" content={backgroundImageSrcData?.original} />
                <Meta name="twitter:image" content={backgroundImageSrcData?.original} />
            </Show>
            <Show when={seo() && seo()?.noIndex}>
                <Meta name="robots" content="noindex,nofollow" />
            </Show>
        </ErrorCatcher>
    );
};
