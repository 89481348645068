import { gql } from 'graphql-request';

const GET_POSTS_QUERY = gql`
    query GetBlogPosts($tags: [String], $limit: Int, $offset: Int) {
        posts: resources(type: "blog", tags: $tags, limit: $limit, offset: $offset, order: DESC) {
            content
        }
    }
`;

export { GET_POSTS_QUERY };
