import { For, Show } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';
import { ImageWordpress } from '../../types/shared';
import { Logo } from './logo/logo';
import { A } from '@solidjs/router';

export type LogoType = {
    image: ImageWordpress;
    title: string;
};

export type LogosGridProps = {
    logos: LogoType[];
    logosUrl: string;
};

export const LogosGrid = (props: LogosGridProps) => {
    return (
        <ErrorCatcher componentName="LogosGrid">
            <div class="mobile:gap-xs desktop:gap-s laptop:p-l bigMobile:px-[8.5rem] tablet:p-0 mobile:p-s mt-xxl desktop:p-xxl gap-xxs desktopXL:p-xxxl grid w-full grid-cols-3 p-0">
                <For each={props.logos}>
                    {(logo: LogoType, i) => {
                        return (
                            <Show when={props.logosUrl} fallback={<Logo logo={logo} index={i()} />}>
                                <A href={props.logosUrl} aria-label={'Se våra projekt'}>
                                    <Logo logo={logo} index={i()} />
                                </A>
                            </Show>
                        );
                    }}
                </For>
            </div>
        </ErrorCatcher>
    );
};

LogosGrid.parseProps = (atts: any) => {
    return {
        logos: atts.logos,
        logosUrl: atts.logosUrl,
    };
};
