import clsx from 'clsx';
import { QuoteProps } from './quote-types';
import { textColorClasses, fillColorClasses } from '../../style/color-classes';
import { QuotationIcon } from '../../components/icons-library/quotation';
import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import tailwindConfig from '../../../tailwind.config';

export const Quote = (props: QuoteProps) => {
    const newLocal = useContext(AppContext);
    const { viewport } = newLocal;
    const isTablet = () => {
        if (viewport.width >= tailwindConfig.theme.extend.screenValues.tabletValue) {
            return true;
        } else {
            return false;
        }
    };

    const wrapperClasses = 'text-paragraphXXL my-[2em] flex tablet:gap-16 gap-8';

    const textColorClass = props.textColor && textColorClasses[props.textColor.name];
    const fillColorClass = props.quoteMarkColor && fillColorClasses[props.quoteMarkColor.name];

    return (
        <blockquote class={clsx(wrapperClasses, textColorClass)}>
            <figure>
                <QuotationIcon
                    width={isTablet() ? '6rem' : '3rem'}
                    height={isTablet() ? '5rem' : '2rem'}
                    class={clsx(fillColorClass, 'tablet:translate-y-2')}
                />
            </figure>
            <p>{props.quote}</p>
        </blockquote>
    );
};

Quote.parseProps = (atts: any) => {
    return {
        quote: atts.children,
        textColor: atts.textColor,
        quoteMarkColor: atts.quoteMarkColor,
    };
};
