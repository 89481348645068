import { For, Setter } from 'solid-js';
import { CloseIcon } from '../../icons-library/close';
import { MobileSlideMenuItem } from './mobile-slide-menu-item';
import { SearchIcon } from '../../icons-library/search';

export const MobileSlideMenu = (props: MobileSlideMenuProps) => {
    const toggleMenu = () => {
        const body = document.querySelector('body');
        body?.classList.toggle('fixed');
        props.setMobileMenuActive(false);
    };
    return (
        <div data-identifier="mobile-slide-menu" class="h-menuSafe text-secondary-600 z-50 flex w-full overflow-y-scroll rounded-l bg-white p-6 ">
            <button aria-label="Stäng meny" class="justify-content align-items absolute right-0 top-0 flex p-4 pr-10 pt-10" onClick={toggleMenu}>
                <CloseIcon fill="black" />
            </button>
            <div data-identifier="mobile-slide-menu-panel" class="tablet:gap-9 mt-16 flex flex-col gap-4">
                <For each={props.menuItems}>{(menuItem) => <MobileSlideMenuItem item={menuItem} />}</For>
                <div class="px-6 py-4">
                    <button
                        aria-label="Öppna sök"
                        class="flex max-w-[fit-content] items-center justify-center rounded-full border p-4 "
                        onClick={() => {
                            props.setSearchActive(true);
                        }}
                    >
                        <SearchIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};
type MobileSlideMenuProps = {
    menuItems: any[];
    setMobileMenuActive: Setter<boolean>;
    setSearchActive: Setter<boolean>;
};
