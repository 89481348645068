import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    width: 95,
    height: 73,
    viewBox: '0 0 95 73',
    stroke: 'none',
    fill: 'currentColor',
};

export const QuotationIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path d="M45.238.837c.428 5.994.642 10.168.642 12.522 0 6.208-.535 12.737-1.605 19.586-2.355 12.844-7.278 22.69-14.77 29.54C22.227 69.335 12.7 72.76.928 72.76l2.89-15.412c10.703 0 17.125-6.208 19.265-18.623l1.284-6.743H7.03L12.166.837h33.072Zm48.484 0c.428 5.994.642 10.168.642 12.522 0 6.208-.535 12.737-1.605 19.586-2.355 12.844-7.171 22.69-14.45 29.54-7.277 6.85-16.91 10.275-28.897 10.275l2.89-15.412c5.565 0 9.846-1.498 12.843-4.495s5.138-7.706 6.422-14.128l.963-6.743H55.192L60.65.837h33.072Z" />
        </SvgIcon>
    );
};
