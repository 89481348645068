import { DividerComponentProps } from './divider-types';

export const Divider = (props: DividerComponentProps) => {

    const getHeight = () => {
        if (!props.height) {
            return 'h-[5rem] tablet:h-[7rem]';
        }
        switch (props.height) {
            case 'xs':
                return 'h-[2rem]';
            case 's':
                return 'h-[2rem] tablet:h-[4rem]';
            case 'm':
                return 'h-[5rem] tablet:h-[7rem]';
            case 'l':
                return 'h-[7rem] tablet:h-[10rem]';
            case 'xl':
                return 'h-[10rem] tablet:h-[15rem]';
            default:
                return 'h-[5rem] tablet:h-[7rem]';
        }
    };

    return (
        <div class={getHeight()}>
        </div>
    );
};

Divider.parseProps = (atts: any) => {
    return atts;
};
