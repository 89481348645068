import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    stroke: 'currentColor',
    fill: 'none',
};

export const SearchIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path d="M18.3335 17.8333L13.0469 12.5466" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M8.33268 14.5001C12.0146 14.5001 14.9993 11.5153 14.9993 7.83341C14.9993 4.15152 12.0146 1.16675 8.33268 1.16675C4.65078 1.16675 1.66602 4.15152 1.66602 7.83341C1.66602 11.5153 4.65078 14.5001 8.33268 14.5001Z"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </SvgIcon>
    );
};
