import clsx from 'clsx';
import { createEffect, mergeProps } from 'solid-js';
import { ColorName, textColorClasses } from '../../style/color-classes';
import { ErrorCatcher } from '../../tools/error-catcher';
import { TextProps } from './text.types';
import { Dynamic } from 'solid-js/web';

const defaultProps = {
    align: 'left',
    tag: 'p',
    color: null,
    children: '',
    disableMaxWidth: false,
};

export const Text = (componentProps: TextProps) => {
    const props = mergeProps(defaultProps, componentProps);

    const sizeClass = () =>
        clsx(
            { 'text-paragraphXS': props.fontSize === 'xs' },
            { 'text-paragraphS': props.fontSize === 's' },
            { 'text-paragraphM': props.fontSize === 'm' },
            { 'text-paragraphL': props.fontSize === 'l' },
            { 'text-paragraphXL': props.fontSize === 'xl' },
            { 'text-paragraphXXL': props.fontSize === 'xxl' },
        );
    const colorClass = () => (props.color ? textColorClasses[props.color as ColorName] : '');
    const alignClass = () =>
        clsx({ 'text-left': props.align === 'left' }, { 'text-center': props.align === 'center' }, { 'text-right': props.align === 'right' });

    // This will make sure that any tags with the attachment datatype are opened in a new tab.
    // The main reason behind it is to make sure that files are opened without solidjs
    createEffect(() => {
        document.querySelectorAll<HTMLAnchorElement>('[data-type="attachment"]').forEach((atag) => {
            atag.target = '_blank';
        });

        // Make sure any outward http links are converted to https
        document.querySelectorAll<HTMLAnchorElement>('a').forEach((atag) => {
            if (atag.href?.match('http://') && window.location.protocol === 'https') {
                atag.href = atag.href.replace('http://', 'https://');
            }
        });
 
    });

    return (
        <ErrorCatcher componentName="Text">
            <Dynamic
                component={props.tag}
                class={clsx(
                    'w-full',
                    { 'max-w-textMaxWidth': !props.disableMaxWidth },
                    colorClass(),
                    sizeClass(),
                    alignClass(),
                    { 'mb-[1em]': !props.disableMargins },
                    props.classes,
                )}
                innerHTML={props.children}
            ></Dynamic>
        </ErrorCatcher>
    );
};

Text.parseProps = (atts: any) => {
    return {
        ...atts,
        color: atts.color?.name,
        disableMargins: false,
        disableMaxWidth: false,
    };
};
