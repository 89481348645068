import { mergeProps, useContext } from 'solid-js';
import { ImageWordpress } from '../types/shared';
import { getImageServiceSrcPath } from '../components/image-component/image-component';
import { AppContext } from '../app-context-provider/app-context-provider';

export const getImageServiceSrc = (props: ImageWordpress) => {
    const { imagesServiceUrl, supportedImageFormats } = useContext(AppContext);

    return getImageServiceSrcPath({
        imagesServiceUrl,
        supportedImageFormats,
        src: props.url,
        jwt: props.jwt,
        width: props.width,
        height: props.height,
    });
};
