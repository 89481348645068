import { Route, Routes, useLocation } from '@solidjs/router';
import { gql } from 'graphql-request';
import { createEffect, useContext } from 'solid-js';
import { Title } from 'solid-meta';
import { AppContext } from '../app-context-provider/app-context-provider';
import { FooterContainer } from '../components/footer/footer';
import { HeaderContainer } from '../components/header/header';
import { PageContainer } from '../components/page/page';
import { FooterColorThemeName, PageColorThemeName, footerColorTheme, headerColorTheme, pageColorThemeClasses } from '../style/color-classes';
import { ErrorCatcher } from '../tools/error-catcher';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import { removeTrailingSlash } from '../tools/remove-trailing-slash';

export const GET_PAGE_QUERY = gql`
    query GetPage($key: String!) {
        page: resource(key: $key) {
            content
            key
        }
    }
`;

export const App = () => {
    dayjs.locale('sv'); // Setting the locale of dayjs to swedish globally
    const { createCachedResource, setPageTheme } = useContext(AppContext);
    const location = useLocation();

    const [pageContent] = createCachedResource(GET_PAGE_QUERY, () => ({ key: removeTrailingSlash(location.pathname) }), true);

    const getMyPageTheme = () => {
        const pageThemeObject =
            pageColorThemeClasses[(pageContent()?.page?.content?.page_settings?.page_theme as PageColorThemeName) || PageColorThemeName.Light];
        const footerThemeObject =
            footerColorTheme[(pageContent()?.page?.content?.page_settings?.footer_theme as FooterColorThemeName) || FooterColorThemeName.Primary];
        const headerThemeObject = headerColorTheme[(pageContent()?.page?.content?.page_settings?.page_theme as PageColorThemeName) || PageColorThemeName.Light];
        const transparentSiteHeader = (pageContent()?.page?.content?.page_settings?.transparent_site_header as boolean) || false;
        const postType = pageContent()?.page?.content?.type as string;

        return {
            colors: pageThemeObject,
            footerColors: footerThemeObject,
            headerColors: headerThemeObject,
            transparentSiteHeader,
            postType,
        };
    };

    createEffect(() => {
        setPageTheme(getMyPageTheme());
    });

    return (
        <div class={`${getMyPageTheme().colors.classes}`}>
            <Title>TwoFour</Title>
            <HeaderContainer />
            <ErrorCatcher componentName="App">
                { /* -93px to composensate for the stocky menu */ }
                <main id="app" class="mt-[-93px]">
                    <Routes>
                        {/*
                            NOTE: When adding new routes here -> If you want to render a menu on the
                            route and its subroute components, you need to render the menu component inside 
                            a portal leading back here. We need context from the page/post to be able to 
                            get the correct menu from the db. Hence this annoying setup.

                            NOTE 2: We only add these dynamic routes if there's a value for the entity, otherwise 
                            it will act as a "catch all"-route and catch stuff that we normally want our PageContainer to handle.

                            NOTE 3: Dont forget to add menu portal when creating new routes!
                            */}
                        <Route path="/*" element={<PageContainer postType={getMyPageTheme().postType} />} />
                    </Routes>
                </main>
            </ErrorCatcher>
            <FooterContainer useFooterMargin={pageContent()?.page?.content?.page_settings?.use_footer_margin} limitFooterContent={pageContent()?.page?.content?.page_settings?.limit_footer_content} />
        </div>
    );
};
