export enum ColorName {
    Black = 'black',
    White = 'white',
    Transparent = 'transparent',
    Primary = 'primary',
    PrimaryLight = 'primaryLight',
    PrimaryDark = 'primaryDark',
    Primary100 = 'primary-100',
    Primary200 = 'primary-200',
    Primary300 = 'primary-300',
    Primary400 = 'primary-400',
    Primary500 = 'primary-500',
    Primary600 = 'primary-600',
    Primary700 = 'primary-700',
    Primary800 = 'primary-800',
    Primary900 = 'primary-900',
    Secondary = 'secondary',
    SecondaryLight = 'secondaryLight',
    SecondaryDark = 'secondaryDark',
    Secondary100 = 'secondary-100',
    Secondary200 = 'secondary-200',
    Secondary300 = 'secondary-300',
    Secondary400 = 'secondary-400',
    Secondary500 = 'secondary-500',
    Secondary600 = 'secondary-600',
    Secondary700 = 'secondary-700',
    Secondary800 = 'secondary-800',
    Secondary900 = 'secondary-900',
    Tertiary = 'tertiary',
    TertiaryLight = 'tertiaryLight',
    TertiaryDark = 'tertiaryDark',
    Tertiary100 = 'tertiary-100',
    Tertiary200 = 'tertiary-200',
    Tertiary300 = 'tertiary-300',
    Tertiary400 = 'tertiary-400',
    Tertiary500 = 'tertiary-500',
    Tertiary600 = 'tertiary-600',
    Tertiary700 = 'tertiary-700',
    Tertiary800 = 'tertiary-800',
    Tertiary900 = 'tertiary-900',
    Neutral100 = 'neutral-100',
    Neutral200 = 'neutral-200',
    Neutral300 = 'neutral-300',
    Neutral400 = 'neutral-400',
    Neutral500 = 'neutral-500',
    Neutral600 = 'neutral-600',
    Neutral700 = 'neutral-700',
    Neutral800 = 'neutral-800',
    Neutral900 = 'neutral-900',
    Accent1 = 'accent1',
    Accent1100 = 'accent1-100',
    Accent1200 = 'accent1-200',
    Accent1300 = 'accent1-300',
    Accent1400 = 'accent1-400',
    Accent1500 = 'accent1-500',
    Accent1600 = 'accent1-600',
    Accent1700 = 'accent1-700',
    Accent1800 = 'accent1-800',
    Accent1900 = 'accent1-900',
    Accent2 = 'accent-2',
    Accent3 = 'accent-3',
    BlackTransparent03 = 'blackTransparent-03',
    BlackTransparent06 = 'blackTransparent-06',
    BlackTransparent10 = 'blackTransparent-10',
    BlackTransparent20 = 'blackTransparent-20',
    BlackTransparent30 = 'blackTransparent-30',
    BlackTransparent40 = 'blackTransparent-40',
    BlackTransparent50 = 'blackTransparent-50',
    BlackTransparent60 = 'blackTransparent-60',
    BlackTransparent70 = 'blackTransparent-70',
    BlackTransparent80 = 'blackTransparent-80',
    BlackTransparent90 = 'blackTransparent-90',

    WhiteTransparent10 = 'whiteTransparent-10',
    WhiteTransparent20 = 'whiteTransparent-20',
    WhiteTransparent30 = 'whiteTransparent-30',
    WhiteTransparent40 = 'whiteTransparent-40',
    WhiteTransparent50 = 'whiteTransparent-50',
    WhiteTransparent60 = 'whiteTransparent-60',
    WhiteTransparent70 = 'whiteTransparent-70',
    WhiteTransparent80 = 'whiteTransparent-80',
    WhiteTransparent90 = 'whiteTransparent-90',
    noValue = '',
}

export enum ThemeName {
    Primary = 'primary',
    Primary500 = 'primary-500',
    Primary100 = 'primary-100',
    Primary600 = 'primary-600',
    Secondary = 'secondary',
    Secondary500 = 'secondary-500',
    Secondary600 = 'secondary-600',
    Secondary700 = 'secondary-700',
    Tertiary = 'tertiary',
    Tertiary200 = 'tertiary-200',
    Tertiary500 = 'tertiary-500',
    Tertiary700 = 'tertiary-700',
    Tertiary800 = 'tertiary-800',
    Light = 'light',
}

export enum FooterColorThemeName {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
}

export enum PageColorThemeName {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    Light = 'light',
}

export enum ButtonColorName {
    White = 'white',
    Secondary = 'secondary',
    Primary = 'primary',
}

export type SectionColorTheme = {
    classes: string;
    bgColor: string;
    textColor: string;
};

export type HeaderColorTheme = {
    linkColor: ColorName;
    buttonHoverColor: ColorName;
    contactBorderColor: ColorName;
    contactBorderHoverColor: ColorName;
};

export type FooterColorTheme = {
    bgColor: ColorName;
    textColor: ColorName;
    headingColor: ColorName;
    buttonColor: ButtonColorName;
    secondaryButtonColor: ButtonColorName;
    socialMediaButtonBackgroundColor: ColorName;
    linkColor: ColorName;
    descriptionColor: ColorName;
};

export const headerColorTheme: Record<PageColorThemeName, HeaderColorTheme> = {
    primary: {
        linkColor: ColorName.White,
        buttonHoverColor: ColorName.BlackTransparent10,
        contactBorderColor: ColorName.WhiteTransparent50,
        contactBorderHoverColor: ColorName.White,
    },
    secondary: {
        linkColor: ColorName.White,
        buttonHoverColor: ColorName.WhiteTransparent10,
        contactBorderColor: ColorName.WhiteTransparent30,
        contactBorderHoverColor: ColorName.White,
    },
    tertiary: {
        linkColor: ColorName.White,
        buttonHoverColor: ColorName.WhiteTransparent10,
        contactBorderColor: ColorName.WhiteTransparent30,
        contactBorderHoverColor: ColorName.White,
    },
    light: {
        linkColor: ColorName.Neutral900,
        buttonHoverColor: ColorName.BlackTransparent10,
        contactBorderColor: ColorName.BlackTransparent10,
        contactBorderHoverColor: ColorName.Neutral900,
    }
};

export const footerColorTheme: Record<FooterColorThemeName, FooterColorTheme> = {
    primary: {
        bgColor: ColorName.Primary,
        textColor: ColorName.Secondary900,
        headingColor: ColorName.Secondary600,
        buttonColor: ButtonColorName.Secondary,
        secondaryButtonColor: ButtonColorName.Secondary,
        socialMediaButtonBackgroundColor: ColorName.Primary400,
        linkColor: ColorName.Secondary700,
        descriptionColor: ColorName.Secondary900,
    },
    secondary: {
        bgColor: ColorName.Secondary600,
        textColor: ColorName.Secondary200,
        headingColor: ColorName.Secondary100,
        buttonColor: ButtonColorName.Primary,
        secondaryButtonColor: ButtonColorName.White,
        socialMediaButtonBackgroundColor: ColorName.Secondary500,
        linkColor: ColorName.Secondary300,
        descriptionColor: ColorName.Secondary200,
    },
    tertiary: {
        bgColor: ColorName.Tertiary700,
        textColor: ColorName.Tertiary300,
        headingColor: ColorName.Tertiary100,
        buttonColor: ButtonColorName.Primary,
        secondaryButtonColor: ButtonColorName.White,
        socialMediaButtonBackgroundColor: ColorName.Tertiary400,
        linkColor: ColorName.Secondary200,
        descriptionColor: ColorName.Tertiary300,
    },
};

export const pageColorThemeClasses: Record<PageColorThemeName, SectionColorTheme> = {
    primary: {
        classes: `bg-${ColorName.Primary} text-${ColorName.Primary900}`,
        bgColor: ColorName.Primary,
        textColor: ColorName.Primary900,
    },
    secondary: {
        classes: `bg-${ColorName.Secondary700} text-${ColorName.Secondary100}`,
        bgColor: ColorName.Secondary700,
        textColor: ColorName.Secondary100,
    },
    tertiary: {
        classes: `bg-${ColorName.Tertiary800} text-${ColorName.Tertiary100}`,
        bgColor: ColorName.Tertiary800,
        textColor: ColorName.Tertiary100,
    },
    light: {
        classes: `bg-${ColorName.Primary100} text-${ColorName.Neutral900}`,
        bgColor: ColorName.Primary100,
        textColor: ColorName.Neutral900,
    },
};

export const sectionColorThemeClasses: Record<ThemeName, SectionColorTheme> = {
    primary: {
        classes: `bg-${ColorName.Primary} text-${ColorName.Primary900}`,
        bgColor: ColorName.Primary,
        textColor: ColorName.Secondary600,
    },
    'primary-500': {
        classes: `bg-${ColorName.Primary500} text-${ColorName.Primary900}`,
        bgColor: ColorName.Primary500,
        textColor: ColorName.Secondary600,
    },
    'primary-100': {
        classes: `bg-${ColorName.Primary100} text-${ColorName.Primary900}`,
        bgColor: ColorName.Primary100,
        textColor: ColorName.Primary900,
    },
    'primary-600': {
        classes: `bg-${ColorName.Primary600} text-${ColorName.Primary900}`,
        bgColor: ColorName.Primary600,
        textColor: ColorName.Primary900,
    },

    secondary: {
        classes: `bg-${ColorName.Secondary700} text-${ColorName.Secondary100}`,
        bgColor: ColorName.Secondary700,
        textColor: ColorName.Secondary100,
    },
    'secondary-500': {
        classes: `bg-${ColorName.Secondary500} text-${ColorName.Secondary100}`,
        bgColor: ColorName.Secondary500,
        textColor: ColorName.Secondary100,
    },
    'secondary-600': {
        classes: `bg-${ColorName.Secondary600} text-${ColorName.Secondary100}`,
        bgColor: ColorName.Secondary600,
        textColor: ColorName.Secondary100,
    },
    'secondary-700': {
        classes: `bg-${ColorName.Secondary700} text-${ColorName.Secondary100}`,
        bgColor: ColorName.Secondary700,
        textColor: ColorName.Secondary100,
    },

    tertiary: {
        classes: `bg-${ColorName.Tertiary} text-${ColorName.Tertiary100}`,
        bgColor: ColorName.Tertiary,
        textColor: ColorName.Tertiary100,
    },
    'tertiary-200': {
        classes: `bg-${ColorName.Tertiary200} text-${ColorName.Tertiary900}`,
        bgColor: ColorName.Tertiary200,
        textColor: ColorName.Tertiary900,
    },
    'tertiary-500': {
        classes: `bg-${ColorName.Tertiary} text-${ColorName.Tertiary100}`,
        bgColor: ColorName.Tertiary,
        textColor: ColorName.Tertiary100,
    },
    'tertiary-700': {
        classes: `bg-${ColorName.Tertiary700} text-${ColorName.Tertiary900}`,
        bgColor: ColorName.Tertiary700,
        textColor: ColorName.Tertiary900,
    },
    'tertiary-800': {
        classes: `bg-${ColorName.Tertiary800} text-${ColorName.Tertiary900}`,
        bgColor: ColorName.Tertiary800,
        textColor: ColorName.Tertiary900,
    },
    light: {
        classes: `bg-${ColorName.Accent1200} text-${ColorName.Neutral900}`,
        bgColor: ColorName.Accent1200,
        textColor: ColorName.Neutral900,
    },
};

export const textColorClasses: Record<ColorName, string> = {
    black: 'text-black',
    white: 'text-white',
    transparent: 'text-transparent',
    primary: 'text-primary',
    primaryLight: 'text-primary-light',
    primaryDark: 'text-primary-dark',
    'primary-100': 'text-primary-100',
    'primary-200': 'text-primary-200',
    'primary-300': 'text-primary-300',
    'primary-400': 'text-primary-400',
    'primary-500': 'text-primary-500',
    'primary-600': 'text-primary-600',
    'primary-700': 'text-primary-700',
    'primary-800': 'text-primary-800',
    'primary-900': 'text-primary-900',

    secondary: 'text-secondary',
    secondaryLight: 'text-secondary-light',
    secondaryDark: 'text-secondary-dark',
    'secondary-100': 'text-secondary-100',
    'secondary-200': 'text-secondary-200',
    'secondary-300': 'text-secondary-300',
    'secondary-400': 'text-secondary-400',
    'secondary-500': 'text-secondary-500',
    'secondary-600': 'text-secondary-600',
    'secondary-700': 'text-secondary-700',
    'secondary-800': 'text-secondary-800',
    'secondary-900': 'text-secondary-900',

    tertiary: 'text-tertiary',
    tertiaryLight: 'text-tertiary-light',
    tertiaryDark: 'text-tertiary-dark',
    'tertiary-100': 'text-tertiary-100',
    'tertiary-200': 'text-tertiary-200',
    'tertiary-300': 'text-tertiary-300',
    'tertiary-400': 'text-tertiary-400',
    'tertiary-500': 'text-tertiary-500',
    'tertiary-600': 'text-tertiary-600',
    'tertiary-700': 'text-tertiary-700',
    'tertiary-800': 'text-tertiary-800',
    'tertiary-900': 'text-tertiary-900',

    'neutral-100': 'text-neutral-100',
    'neutral-200': 'text-neutral-200',
    'neutral-300': 'text-neutral-300',
    'neutral-400': 'text-neutral-400',
    'neutral-500': 'text-neutral-500',
    'neutral-600': 'text-neutral-600',
    'neutral-700': 'text-neutral-700',
    'neutral-800': 'text-neutral-800',
    'neutral-900': 'text-neutral-900',

    accent1: 'text-accent1',
    'accent1-100': 'text-accent1-100',
    'accent1-200': 'text-accent1-200',
    'accent1-300': 'text-accent1-300',
    'accent1-400': 'text-accent1-400',
    'accent1-500': 'text-accent1-500',
    'accent1-600': 'text-accent1-600',
    'accent1-700': 'text-accent1-700',
    'accent1-800': 'text-accent1-800',
    'accent1-900': 'text-accent1-900',

    'accent-2': 'text-accent2',
    'accent-3': 'text-accent3',

    'blackTransparent-03': 'text-blackTransparent-03',
    'blackTransparent-06': 'text-blackTransparent-06',
    'blackTransparent-10': 'text-blackTransparent-10',
    'blackTransparent-20': 'text-blackTransparent-20',
    'blackTransparent-30': 'text-blackTransparent-30',
    'blackTransparent-40': 'text-blackTransparent-40',
    'blackTransparent-50': 'text-blackTransparent-50',
    'blackTransparent-60': 'text-blackTransparent-60',
    'blackTransparent-70': 'text-blackTransparent-70',
    'blackTransparent-80': 'text-blackTransparent-80',
    'blackTransparent-90': 'text-blackTransparent-90',
    
    'whiteTransparent-10': 'text-whiteTransparent-10',
    'whiteTransparent-20': 'text-whiteTransparent-20',
    'whiteTransparent-30': 'text-whiteTransparent-30',
    'whiteTransparent-40': 'text-whiteTransparent-40',
    'whiteTransparent-50': 'text-whiteTransparent-50',
    'whiteTransparent-60': 'text-whiteTransparent-60',
    'whiteTransparent-70': 'text-whiteTransparent-70',
    'whiteTransparent-80': 'text-whiteTransparent-80',
    'whiteTransparent-90': 'text-whiteTransparent-90',

    '': '',
};

export const bgColorClasses: Record<ColorName, string> = {
    black: 'bg-black',
    white: 'bg-white',
    transparent: 'bg-transparent',
    primary: 'bg-primary',
    primaryLight: 'bg-primary-light',
    primaryDark: 'bg-primary-dark',
    'primary-100': 'bg-primary-100',
    'primary-200': 'bg-primary-200',
    'primary-300': 'bg-primary-300',
    'primary-400': 'bg-primary-400',
    'primary-500': 'bg-primary-500',
    'primary-600': 'bg-primary-600',
    'primary-700': 'bg-primary-700',
    'primary-800': 'bg-primary-800',
    'primary-900': 'bg-primary-900',

    secondary: 'bg-secondary-700',
    secondaryLight: 'bg-secondary-light',
    secondaryDark: 'bg-secondary-dark',
    'secondary-100': 'bg-secondary-100',
    'secondary-200': 'bg-secondary-200',
    'secondary-300': 'bg-secondary-300',
    'secondary-400': 'bg-secondary-400',
    'secondary-500': 'bg-secondary-500',
    'secondary-600': 'bg-secondary-600',
    'secondary-700': 'bg-secondary-700',
    'secondary-800': 'bg-secondary-800',
    'secondary-900': 'bg-secondary-900',

    tertiary: 'bg-tertiary',
    tertiaryLight: 'bg-tertiary-light',
    tertiaryDark: 'bg-tertiary-dark',
    'tertiary-100': 'bg-tertiary-100',
    'tertiary-200': 'bg-tertiary-200',
    'tertiary-300': 'bg-tertiary-300',
    'tertiary-400': 'bg-tertiary-400',
    'tertiary-500': 'bg-tertiary-500',
    'tertiary-600': 'bg-tertiary-600',
    'tertiary-700': 'bg-tertiary-700',
    'tertiary-800': 'bg-tertiary-800',
    'tertiary-900': 'bg-tertiary-900',

    'neutral-100': 'bg-neutral-100',
    'neutral-200': 'bg-neutral-200',
    'neutral-300': 'bg-neutral-300',
    'neutral-400': 'bg-neutral-400',
    'neutral-500': 'bg-neutral-500',
    'neutral-600': 'bg-neutral-600',
    'neutral-700': 'bg-neutral-700',
    'neutral-800': 'bg-neutral-800',
    'neutral-900': 'bg-neutral-900',

    accent1: 'bg-accent1',
    'accent1-100': 'bg-accent1-100',
    'accent1-200': 'bg-accent1-200',
    'accent1-300': 'bg-accent1-300',
    'accent1-400': 'bg-accent1-400',
    'accent1-500': 'bg-accent1-500',
    'accent1-600': 'bg-accent1-600',
    'accent1-700': 'bg-accent1-700',
    'accent1-800': 'bg-accent1-800',
    'accent1-900': 'bg-accent1-900',

    'accent-2': 'bg-accent2',
    'accent-3': 'bg-accent3',

    'blackTransparent-03': 'bg-blackTransparent-03',
    'blackTransparent-06': 'bg-blackTransparent-06',
    'blackTransparent-10': 'bg-blackTransparent-10',
    'blackTransparent-20': 'bg-blackTransparent-20',
    'blackTransparent-30': 'bg-blackTransparent-30',
    'blackTransparent-40': 'bg-blackTransparent-40',
    'blackTransparent-50': 'bg-blackTransparent-50',
    'blackTransparent-60': 'bg-blackTransparent-60',
    'blackTransparent-70': 'bg-blackTransparent-70',
    'blackTransparent-80': 'bg-blackTransparent-80',
    'blackTransparent-90': 'bg-blackTransparent-90',
    
    'whiteTransparent-10': 'bg-whiteTransparent-10',
    'whiteTransparent-20': 'bg-whiteTransparent-20',
    'whiteTransparent-30': 'bg-whiteTransparent-30',
    'whiteTransparent-40': 'bg-whiteTransparent-40',
    'whiteTransparent-50': 'bg-whiteTransparent-50',
    'whiteTransparent-60': 'bg-whiteTransparent-60',
    'whiteTransparent-70': 'bg-whiteTransparent-70',
    'whiteTransparent-80': 'bg-whiteTransparent-80',
    'whiteTransparent-90': 'bg-whiteTransparent-90',

    '': '',
};

export const bgHoverColorClasses: Record<ColorName, string> = {
    black: 'hover:bg-black',
    white: 'hover:bg-white',
    transparent: 'hover:bg-transparent',
    primary: 'hover:bg-primary',
    primaryLight: 'hover:bg-primary-light',
    primaryDark: 'hover:bg-primary-dark',
    'primary-100': 'hover:bg-primary-100',
    'primary-200': 'hover:bg-primary-200',
    'primary-300': 'hover:bg-primary-300',
    'primary-400': 'hover:bg-primary-400',
    'primary-500': 'hover:bg-primary-500',
    'primary-600': 'hover:bg-primary-600',
    'primary-700': 'hover:bg-primary-700',
    'primary-800': 'hover:bg-primary-800',
    'primary-900': 'hover:bg-primary-900',

    secondary: 'hover:bg-secondary-700',
    secondaryLight: 'hover:bg-secondary-light',
    secondaryDark: 'hover:bg-secondary-dark',
    'secondary-100': 'hover:bg-secondary-100',
    'secondary-200': 'hover:bg-secondary-200',
    'secondary-300': 'hover:bg-secondary-300',
    'secondary-400': 'hover:bg-secondary-400',
    'secondary-500': 'hover:bg-secondary-500',
    'secondary-600': 'hover:bg-secondary-600',
    'secondary-700': 'hover:bg-secondary-700',
    'secondary-800': 'hover:bg-secondary-800',
    'secondary-900': 'hover:bg-secondary-900',

    tertiary: 'hover:bg-tertiary',
    tertiaryLight: 'hover:bg-tertiary-light',
    tertiaryDark: 'hover:bg-tertiary-dark',
    'tertiary-100': 'hover:bg-tertiary-100',
    'tertiary-200': 'hover:bg-tertiary-200',
    'tertiary-300': 'hover:bg-tertiary-300',
    'tertiary-400': 'hover:bg-tertiary-400',
    'tertiary-500': 'hover:bg-tertiary-500',
    'tertiary-600': 'hover:bg-tertiary-600',
    'tertiary-700': 'hover:bg-tertiary-700',
    'tertiary-800': 'hover:bg-tertiary-800',
    'tertiary-900': 'hover:bg-tertiary-900',

    'neutral-100': 'hover:bg-neutral-100',
    'neutral-200': 'hover:bg-neutral-200',
    'neutral-300': 'hover:bg-neutral-300',
    'neutral-400': 'hover:bg-neutral-400',
    'neutral-500': 'hover:bg-neutral-500',
    'neutral-600': 'hover:bg-neutral-600',
    'neutral-700': 'hover:bg-neutral-700',
    'neutral-800': 'hover:bg-neutral-800',
    'neutral-900': 'hover:bg-neutral-900',

    accent1: 'hover:bg-accent1',
    'accent1-100': 'hover:bg-accent1-100',
    'accent1-200': 'hover:bg-accent1-200',
    'accent1-300': 'hover:bg-accent1-300',
    'accent1-400': 'hover:bg-accent1-400',
    'accent1-500': 'hover:bg-accent1-500',
    'accent1-600': 'hover:bg-accent1-600',
    'accent1-700': 'hover:bg-accent1-700',
    'accent1-800': 'hover:bg-accent1-800',
    'accent1-900': 'hover:bg-accent1-900',

    'accent-2': 'hover:bg-accent2',
    'accent-3': 'hover:bg-accent3',

    'blackTransparent-03': 'hover:bg-blackTransparent-03',
    'blackTransparent-06': 'hover:bg-blackTransparent-06',
    'blackTransparent-10': 'hover:bg-blackTransparent-10',
    'blackTransparent-20': 'hover:bg-blackTransparent-20',
    'blackTransparent-30': 'hover:bg-blackTransparent-30',
    'blackTransparent-40': 'hover:bg-blackTransparent-40',
    'blackTransparent-50': 'hover:bg-blackTransparent-50',
    'blackTransparent-60': 'hover:bg-blackTransparent-60',
    'blackTransparent-70': 'hover:bg-blackTransparent-70',
    'blackTransparent-80': 'hover:bg-blackTransparent-80',
    'blackTransparent-90': 'hover:bg-blackTransparent-90',
    
    'whiteTransparent-10': 'hover:bg-whiteTransparent-10',
    'whiteTransparent-20': 'hover:bg-whiteTransparent-20',
    'whiteTransparent-30': 'hover:bg-whiteTransparent-30',
    'whiteTransparent-40': 'hover:bg-whiteTransparent-40',
    'whiteTransparent-50': 'hover:bg-whiteTransparent-50',
    'whiteTransparent-60': 'hover:bg-whiteTransparent-60',
    'whiteTransparent-70': 'hover:bg-whiteTransparent-70',
    'whiteTransparent-80': 'hover:bg-whiteTransparent-80',
    'whiteTransparent-90': 'hover:bg-whiteTransparent-90',

    '': '',
};

export const bgFocusColorClasses: Record<ColorName, string> = {
    black: 'focus:bg-black',
    white: 'focus:bg-white',
    transparent: 'focus:bg-transparent',
    primary: 'focus:bg-primary',
    primaryLight: 'focus:bg-primary-light',
    primaryDark: 'focus:bg-primary-dark',
    'primary-100': 'focus:bg-primary-100',
    'primary-200': 'focus:bg-primary-200',
    'primary-300': 'focus:bg-primary-300',
    'primary-400': 'focus:bg-primary-400',
    'primary-500': 'focus:bg-primary-500',
    'primary-600': 'focus:bg-primary-600',
    'primary-700': 'focus:bg-primary-700',
    'primary-800': 'focus:bg-primary-800',
    'primary-900': 'focus:bg-primary-900',

    secondary: 'focus:bg-secondary-700',
    secondaryLight: 'focus:bg-secondary-light',
    secondaryDark: 'focus:bg-secondary-dark',
    'secondary-100': 'focus:bg-secondary-100',
    'secondary-200': 'focus:bg-secondary-200',
    'secondary-300': 'focus:bg-secondary-300',
    'secondary-400': 'focus:bg-secondary-400',
    'secondary-500': 'focus:bg-secondary-500',
    'secondary-600': 'focus:bg-secondary-600',
    'secondary-700': 'focus:bg-secondary-700',
    'secondary-800': 'focus:bg-secondary-800',
    'secondary-900': 'focus:bg-secondary-900',

    tertiary: 'focus:bg-tertiary',
    tertiaryLight: 'focus:bg-tertiary-light',
    tertiaryDark: 'focus:bg-tertiary-dark',
    'tertiary-100': 'focus:bg-tertiary-100',
    'tertiary-200': 'focus:bg-tertiary-200',
    'tertiary-300': 'focus:bg-tertiary-300',
    'tertiary-400': 'focus:bg-tertiary-400',
    'tertiary-500': 'focus:bg-tertiary-500',
    'tertiary-600': 'focus:bg-tertiary-600',
    'tertiary-700': 'focus:bg-tertiary-700',
    'tertiary-800': 'focus:bg-tertiary-800',
    'tertiary-900': 'focus:bg-tertiary-900',

    'neutral-100': 'focus:bg-neutral-100',
    'neutral-200': 'focus:bg-neutral-200',
    'neutral-300': 'focus:bg-neutral-300',
    'neutral-400': 'focus:bg-neutral-400',
    'neutral-500': 'focus:bg-neutral-500',
    'neutral-600': 'focus:bg-neutral-600',
    'neutral-700': 'focus:bg-neutral-700',
    'neutral-800': 'focus:bg-neutral-800',
    'neutral-900': 'focus:bg-neutral-900',

    accent1: 'focus:bg-accent1',
    'accent1-100': 'focus:bg-accent1-100',
    'accent1-200': 'focus:bg-accent1-200',
    'accent1-300': 'focus:bg-accent1-300',
    'accent1-400': 'focus:bg-accent1-400',
    'accent1-500': 'focus:bg-accent1-500',
    'accent1-600': 'focus:bg-accent1-600',
    'accent1-700': 'focus:bg-accent1-700',
    'accent1-800': 'focus:bg-accent1-800',
    'accent1-900': 'focus:bg-accent1-900',

    'accent-2': 'focus:bg-accent2',
    'accent-3': 'focus:bg-accent3',

    'blackTransparent-03': 'focus:bg-blackTransparent-03',
    'blackTransparent-06': 'focus:bg-blackTransparent-06',
    'blackTransparent-10': 'focus:bg-blackTransparent-10',
    'blackTransparent-20': 'focus:bg-blackTransparent-20',
    'blackTransparent-30': 'focus:bg-blackTransparent-30',
    'blackTransparent-40': 'focus:bg-blackTransparent-40',
    'blackTransparent-50': 'focus:bg-blackTransparent-50',
    'blackTransparent-60': 'focus:bg-blackTransparent-60',
    'blackTransparent-70': 'focus:bg-blackTransparent-70',
    'blackTransparent-80': 'focus:bg-blackTransparent-80',
    'blackTransparent-90': 'focus:bg-blackTransparent-90',
    
    'whiteTransparent-10': 'focus:bg-whiteTransparent-10',
    'whiteTransparent-20': 'focus:bg-whiteTransparent-20',
    'whiteTransparent-30': 'focus:bg-whiteTransparent-30',
    'whiteTransparent-40': 'focus:bg-whiteTransparent-40',
    'whiteTransparent-50': 'focus:bg-whiteTransparent-50',
    'whiteTransparent-60': 'focus:bg-whiteTransparent-60',
    'whiteTransparent-70': 'focus:bg-whiteTransparent-70',
    'whiteTransparent-80': 'focus:bg-whiteTransparent-80',
    'whiteTransparent-90': 'focus:bg-whiteTransparent-90',

    '': '',
};

export const fillColorClasses: Record<ColorName, string> = {
    black: 'fill-black',
    white: 'fill-white',
    transparent: 'fill-transparent',
    primary: 'fill-primary',
    primaryLight: 'fill-primary-light',
    primaryDark: 'fill-primary-dark',
    'primary-100': 'fill-primary-100',
    'primary-200': 'fill-primary-200',
    'primary-300': 'fill-primary-300',
    'primary-400': 'fill-primary-400',
    'primary-500': 'fill-primary-500',
    'primary-600': 'fill-primary-600',
    'primary-700': 'fill-primary-700',
    'primary-800': 'fill-primary-800',
    'primary-900': 'fill-primary-900',

    secondary: 'fill-secondary',
    secondaryLight: 'fill-secondary-light',
    secondaryDark: 'fill-secondary-dark',
    'secondary-100': 'fill-secondary-100',
    'secondary-200': 'fill-secondary-200',
    'secondary-300': 'fill-secondary-300',
    'secondary-400': 'fill-secondary-400',
    'secondary-500': 'fill-secondary-500',
    'secondary-600': 'fill-secondary-600',
    'secondary-700': 'fill-secondary-700',
    'secondary-800': 'fill-secondary-800',
    'secondary-900': 'fill-secondary-900',

    tertiary: 'fill-tertiary',
    tertiaryLight: 'fill-tertiary-light',
    tertiaryDark: 'fill-tertiary-dark',
    'tertiary-100': 'fill-tertiary-100',
    'tertiary-200': 'fill-tertiary-200',
    'tertiary-300': 'fill-tertiary-300',
    'tertiary-400': 'fill-tertiary-400',
    'tertiary-500': 'fill-tertiary-500',
    'tertiary-600': 'fill-tertiary-600',
    'tertiary-700': 'fill-tertiary-700',
    'tertiary-800': 'fill-tertiary-800',
    'tertiary-900': 'fill-tertiary-900',

    'neutral-100': 'fill-neutral-100',
    'neutral-200': 'fill-neutral-200',
    'neutral-300': 'fill-neutral-300',
    'neutral-400': 'fill-neutral-400',
    'neutral-500': 'fill-neutral-500',
    'neutral-600': 'fill-neutral-600',
    'neutral-700': 'fill-neutral-700',
    'neutral-800': 'fill-neutral-800',
    'neutral-900': 'fill-neutral-900',

    accent1: 'fill-accent1',
    'accent1-100': 'fill-accent1-100',
    'accent1-200': 'fill-accent1-200',
    'accent1-300': 'fill-accent1-300',
    'accent1-400': 'fill-accent1-400',
    'accent1-500': 'fill-accent1-500',
    'accent1-600': 'fill-accent1-600',
    'accent1-700': 'fill-accent1-700',
    'accent1-800': 'fill-accent1-800',
    'accent1-900': 'fill-accent1-900',

    'accent-2': 'fill-accent2',
    'accent-3': 'fill-accent3',
    
    'blackTransparent-03': 'fill-blackTransparent-03',
    'blackTransparent-06': 'fill-blackTransparent-06',
    'blackTransparent-10': 'fill-blackTransparent-10',
    'blackTransparent-20': 'fill-blackTransparent-20',
    'blackTransparent-30': 'fill-blackTransparent-30',
    'blackTransparent-40': 'fill-blackTransparent-40',
    'blackTransparent-50': 'fill-blackTransparent-50',
    'blackTransparent-60': 'fill-blackTransparent-60',
    'blackTransparent-70': 'fill-blackTransparent-70',
    'blackTransparent-80': 'fill-blackTransparent-80',
    'blackTransparent-90': 'fill-blackTransparent-90',
    
    'whiteTransparent-10': 'fill-whiteTransparent-10',
    'whiteTransparent-20': 'fill-whiteTransparent-20',
    'whiteTransparent-30': 'fill-whiteTransparent-30',
    'whiteTransparent-40': 'fill-whiteTransparent-40',
    'whiteTransparent-50': 'fill-whiteTransparent-50',
    'whiteTransparent-60': 'fill-whiteTransparent-60',
    'whiteTransparent-70': 'fill-whiteTransparent-70',
    'whiteTransparent-80': 'fill-whiteTransparent-80',
    'whiteTransparent-90': 'fill-whiteTransparent-90',

    '': '',
};

export const strokeColorClasses: Record<ColorName, string> = {
    black: 'stroke-black',
    white: 'stroke-white',
    transparent: 'stroke-transparent',
    primary: 'stroke-primary',
    primaryLight: 'stroke-primary-light',
    primaryDark: 'stroke-primary-dark',
    'primary-100': 'stroke-primary-100',
    'primary-200': 'stroke-primary-200',
    'primary-300': 'stroke-primary-300',
    'primary-400': 'stroke-primary-400',
    'primary-500': 'stroke-primary-500',
    'primary-600': 'stroke-primary-600',
    'primary-700': 'stroke-primary-700',
    'primary-800': 'stroke-primary-800',
    'primary-900': 'stroke-primary-900',

    secondary: 'stroke-secondary',
    secondaryLight: 'stroke-secondary-light',
    secondaryDark: 'stroke-secondary-dark',
    'secondary-100': 'stroke-secondary-100',
    'secondary-200': 'stroke-secondary-200',
    'secondary-300': 'stroke-secondary-300',
    'secondary-400': 'stroke-secondary-400',
    'secondary-500': 'stroke-secondary-500',
    'secondary-600': 'stroke-secondary-600',
    'secondary-700': 'stroke-secondary-700',
    'secondary-800': 'stroke-secondary-800',
    'secondary-900': 'stroke-secondary-900',

    tertiary: 'stroke-tertiary',
    tertiaryLight: 'stroke-tertiary-light',
    tertiaryDark: 'stroke-tertiary-dark',
    'tertiary-100': 'stroke-tertiary-100',
    'tertiary-200': 'stroke-tertiary-200',
    'tertiary-300': 'stroke-tertiary-300',
    'tertiary-400': 'stroke-tertiary-400',
    'tertiary-500': 'stroke-tertiary-500',
    'tertiary-600': 'stroke-tertiary-600',
    'tertiary-700': 'stroke-tertiary-700',
    'tertiary-800': 'stroke-tertiary-800',
    'tertiary-900': 'stroke-tertiary-900',

    'neutral-100': 'stroke-neutral-100',
    'neutral-200': 'stroke-neutral-200',
    'neutral-300': 'stroke-neutral-300',
    'neutral-400': 'stroke-neutral-400',
    'neutral-500': 'stroke-neutral-500',
    'neutral-600': 'stroke-neutral-600',
    'neutral-700': 'stroke-neutral-700',
    'neutral-800': 'stroke-neutral-800',
    'neutral-900': 'stroke-neutral-900',

    accent1: 'stroke-accent1',
    'accent1-100': 'stroke-accent1-100',
    'accent1-200': 'stroke-accent1-200',
    'accent1-300': 'stroke-accent1-300',
    'accent1-400': 'stroke-accent1-400',
    'accent1-500': 'stroke-accent1-500',
    'accent1-600': 'stroke-accent1-600',
    'accent1-700': 'stroke-accent1-700',
    'accent1-800': 'stroke-accent1-800',
    'accent1-900': 'stroke-accent1-900',

    'accent-2': 'stroke-accent2',
    'accent-3': 'stroke-accent3',
    
    'blackTransparent-03': 'stroke-blackTransparent-03',
    'blackTransparent-06': 'stroke-blackTransparent-06',
    'blackTransparent-10': 'stroke-blackTransparent-10',
    'blackTransparent-20': 'stroke-blackTransparent-20',
    'blackTransparent-30': 'stroke-blackTransparent-30',
    'blackTransparent-40': 'stroke-blackTransparent-40',
    'blackTransparent-50': 'stroke-blackTransparent-50',
    'blackTransparent-60': 'stroke-blackTransparent-60',
    'blackTransparent-70': 'stroke-blackTransparent-70',
    'blackTransparent-80': 'stroke-blackTransparent-80',
    'blackTransparent-90': 'stroke-blackTransparent-90',
    
    'whiteTransparent-10': 'stroke-whiteTransparent-10',
    'whiteTransparent-20': 'stroke-whiteTransparent-20',
    'whiteTransparent-30': 'stroke-whiteTransparent-30',
    'whiteTransparent-40': 'stroke-whiteTransparent-40',
    'whiteTransparent-50': 'stroke-whiteTransparent-50',
    'whiteTransparent-60': 'stroke-whiteTransparent-60',
    'whiteTransparent-70': 'stroke-whiteTransparent-70',
    'whiteTransparent-80': 'stroke-whiteTransparent-80',
    'whiteTransparent-90': 'stroke-whiteTransparent-90',

    '': '',
};

export const borderColorClasses: Record<ColorName, string> = {
    black: 'border-black',
    white: 'border-white',
    transparent: 'border-transparent',
    primary: 'border-primary',
    primaryLight: 'border-primary-light',
    primaryDark: 'border-primary-dark',
    'primary-100': 'border-primary-100',
    'primary-200': 'border-primary-200',
    'primary-300': 'border-primary-300',
    'primary-400': 'border-primary-400',
    'primary-500': 'border-primary-500',
    'primary-600': 'border-primary-600',
    'primary-700': 'border-primary-700',
    'primary-800': 'border-primary-800',
    'primary-900': 'border-primary-900',

    secondary: 'border-secondary',
    secondaryLight: 'border-secondary-light',
    secondaryDark: 'border-secondary-dark',
    'secondary-100': 'border-secondary-100',
    'secondary-200': 'border-secondary-200',
    'secondary-300': 'border-secondary-300',
    'secondary-400': 'border-secondary-400',
    'secondary-500': 'border-secondary-500',
    'secondary-600': 'border-secondary-600',
    'secondary-700': 'border-secondary-700',
    'secondary-800': 'border-secondary-800',
    'secondary-900': 'border-secondary-900',

    tertiary: 'border-tertiary',
    tertiaryLight: 'border-tertiary-light',
    tertiaryDark: 'border-tertiary-dark',
    'tertiary-100': 'border-tertiary-100',
    'tertiary-200': 'border-tertiary-200',
    'tertiary-300': 'border-tertiary-300',
    'tertiary-400': 'border-tertiary-400',
    'tertiary-500': 'border-tertiary-500',
    'tertiary-600': 'border-tertiary-600',
    'tertiary-700': 'border-tertiary-700',
    'tertiary-800': 'border-tertiary-800',
    'tertiary-900': 'border-tertiary-900',

    'neutral-100': 'border-neutral-100',
    'neutral-200': 'border-neutral-200',
    'neutral-300': 'border-neutral-300',
    'neutral-400': 'border-neutral-400',
    'neutral-500': 'border-neutral-500',
    'neutral-600': 'border-neutral-600',
    'neutral-700': 'border-neutral-700',
    'neutral-800': 'border-neutral-800',
    'neutral-900': 'border-neutral-900',

    accent1: 'border-accent1',
    'accent1-100': 'border-accent1-100',
    'accent1-200': 'border-accent1-200',
    'accent1-300': 'border-accent1-300',
    'accent1-400': 'border-accent1-400',
    'accent1-500': 'border-accent1-500',
    'accent1-600': 'border-accent1-600',
    'accent1-700': 'border-accent1-700',
    'accent1-800': 'border-accent1-800',
    'accent1-900': 'border-accent1-900',

    'accent-2': 'border-accent2',
    'accent-3': 'border-accent3',
    
    'blackTransparent-03': 'border-blackTransparent-03',
    'blackTransparent-06': 'border-blackTransparent-06',
    'blackTransparent-10': 'border-blackTransparent-10',
    'blackTransparent-20': 'border-blackTransparent-20',
    'blackTransparent-30': 'border-blackTransparent-30',
    'blackTransparent-40': 'border-blackTransparent-40',
    'blackTransparent-50': 'border-blackTransparent-50',
    'blackTransparent-60': 'border-blackTransparent-60',
    'blackTransparent-70': 'border-blackTransparent-70',
    'blackTransparent-80': 'border-blackTransparent-80',
    'blackTransparent-90': 'border-blackTransparent-90',
    
    'whiteTransparent-10': 'border-whiteTransparent-10',
    'whiteTransparent-20': 'border-whiteTransparent-20',
    'whiteTransparent-30': 'border-whiteTransparent-30',
    'whiteTransparent-40': 'border-whiteTransparent-40',
    'whiteTransparent-50': 'border-whiteTransparent-50',
    'whiteTransparent-60': 'border-whiteTransparent-60',
    'whiteTransparent-70': 'border-whiteTransparent-70',
    'whiteTransparent-80': 'border-whiteTransparent-80',
    'whiteTransparent-90': 'border-whiteTransparent-90',

    '': '',
};

export const borderHoverColorClasses: Record<ColorName, string> = {
    black: 'hover:border-black',
    white: 'hover:border-white',
    transparent: 'hover:border-transparent',
    primary: 'hover:border-primary',
    primaryLight: 'hover:border-primary-light',
    primaryDark: 'hover:border-primary-dark',
    'primary-100': 'hover:border-primary-100',
    'primary-200': 'hover:border-primary-200',
    'primary-300': 'hover:border-primary-300',
    'primary-400': 'hover:border-primary-400',
    'primary-500': 'hover:border-primary-500',
    'primary-600': 'hover:border-primary-600',
    'primary-700': 'hover:border-primary-700',
    'primary-800': 'hover:border-primary-800',
    'primary-900': 'hover:border-primary-900',

    secondary: 'hover:border-secondary',
    secondaryLight: 'hover:border-secondary-light',
    secondaryDark: 'hover:border-secondary-dark',
    'secondary-100': 'hover:border-secondary-100',
    'secondary-200': 'hover:border-secondary-200',
    'secondary-300': 'hover:border-secondary-300',
    'secondary-400': 'hover:border-secondary-400',
    'secondary-500': 'hover:border-secondary-500',
    'secondary-600': 'hover:border-secondary-600',
    'secondary-700': 'hover:border-secondary-700',
    'secondary-800': 'hover:border-secondary-800',
    'secondary-900': 'hover:border-secondary-900',

    tertiary: 'hover:border-tertiary',
    tertiaryLight: 'hover:border-tertiary-light',
    tertiaryDark: 'hover:border-tertiary-dark',
    'tertiary-100': 'hover:border-tertiary-100',
    'tertiary-200': 'hover:border-tertiary-200',
    'tertiary-300': 'hover:border-tertiary-300',
    'tertiary-400': 'hover:border-tertiary-400',
    'tertiary-500': 'hover:border-tertiary-500',
    'tertiary-600': 'hover:border-tertiary-600',
    'tertiary-700': 'hover:border-tertiary-700',
    'tertiary-800': 'hover:border-tertiary-800',
    'tertiary-900': 'hover:border-tertiary-900',

    'neutral-100': 'hover:border-neutral-100',
    'neutral-200': 'hover:border-neutral-200',
    'neutral-300': 'hover:border-neutral-300',
    'neutral-400': 'hover:border-neutral-400',
    'neutral-500': 'hover:border-neutral-500',
    'neutral-600': 'hover:border-neutral-600',
    'neutral-700': 'hover:border-neutral-700',
    'neutral-800': 'hover:border-neutral-800',
    'neutral-900': 'hover:border-neutral-900',

    accent1: 'hover:border-accent1',
    'accent1-100': 'hover:border-accent1-100',
    'accent1-200': 'hover:border-accent1-200',
    'accent1-300': 'hover:border-accent1-300',
    'accent1-400': 'hover:border-accent1-400',
    'accent1-500': 'hover:border-accent1-500',
    'accent1-600': 'hover:border-accent1-600',
    'accent1-700': 'hover:border-accent1-700',
    'accent1-800': 'hover:border-accent1-800',
    'accent1-900': 'hover:border-accent1-900',

    'accent-2': 'hover:border-accent2',
    'accent-3': 'hover:border-accent3',
    
    'blackTransparent-03': 'hover:border-blackTransparent-03',
    'blackTransparent-06': 'hover:border-blackTransparent-06',
    'blackTransparent-10': 'hover:border-blackTransparent-10',
    'blackTransparent-20': 'hover:border-blackTransparent-20',
    'blackTransparent-30': 'hover:border-blackTransparent-30',
    'blackTransparent-40': 'hover:border-blackTransparent-40',
    'blackTransparent-50': 'hover:border-blackTransparent-50',
    'blackTransparent-60': 'hover:border-blackTransparent-60',
    'blackTransparent-70': 'hover:border-blackTransparent-70',
    'blackTransparent-80': 'hover:border-blackTransparent-80',
    'blackTransparent-90': 'hover:border-blackTransparent-90',
    
    'whiteTransparent-10': 'hover:border-whiteTransparent-10',
    'whiteTransparent-20': 'hover:border-whiteTransparent-20',
    'whiteTransparent-30': 'hover:border-whiteTransparent-30',
    'whiteTransparent-40': 'hover:border-whiteTransparent-40',
    'whiteTransparent-50': 'hover:border-whiteTransparent-50',
    'whiteTransparent-60': 'hover:border-whiteTransparent-60',
    'whiteTransparent-70': 'hover:border-whiteTransparent-70',
    'whiteTransparent-80': 'hover:border-whiteTransparent-80',
    'whiteTransparent-90': 'hover:border-whiteTransparent-90',

    '': '',
};

export const borderFocusColorClasses: Record<ColorName, string> = {
    black: 'focus:border-black',
    white: 'focus:border-white',
    transparent: 'focus:border-transparent',
    primary: 'focus:border-primary',
    primaryLight: 'focus:border-primary-light',
    primaryDark: 'focus:border-primary-dark',
    'primary-100': 'focus:border-primary-100',
    'primary-200': 'focus:border-primary-200',
    'primary-300': 'focus:border-primary-300',
    'primary-400': 'focus:border-primary-400',
    'primary-500': 'focus:border-primary-500',
    'primary-600': 'focus:border-primary-600',
    'primary-700': 'focus:border-primary-700',
    'primary-800': 'focus:border-primary-800',
    'primary-900': 'focus:border-primary-900',

    secondary: 'focus:border-secondary',
    secondaryLight: 'focus:border-secondary-light',
    secondaryDark: 'focus:border-secondary-dark',
    'secondary-100': 'focus:border-secondary-100',
    'secondary-200': 'focus:border-secondary-200',
    'secondary-300': 'focus:border-secondary-300',
    'secondary-400': 'focus:border-secondary-400',
    'secondary-500': 'focus:border-secondary-500',
    'secondary-600': 'focus:border-secondary-600',
    'secondary-700': 'focus:border-secondary-700',
    'secondary-800': 'focus:border-secondary-800',
    'secondary-900': 'focus:border-secondary-900',

    tertiary: 'focus:border-tertiary',
    tertiaryLight: 'focus:border-tertiary-light',
    tertiaryDark: 'focus:border-tertiary-dark',
    'tertiary-100': 'focus:border-tertiary-100',
    'tertiary-200': 'focus:border-tertiary-200',
    'tertiary-300': 'focus:border-tertiary-300',
    'tertiary-400': 'focus:border-tertiary-400',
    'tertiary-500': 'focus:border-tertiary-500',
    'tertiary-600': 'focus:border-tertiary-600',
    'tertiary-700': 'focus:border-tertiary-700',
    'tertiary-800': 'focus:border-tertiary-800',
    'tertiary-900': 'focus:border-tertiary-900',

    'neutral-100': 'focus:border-neutral-100',
    'neutral-200': 'focus:border-neutral-200',
    'neutral-300': 'focus:border-neutral-300',
    'neutral-400': 'focus:border-neutral-400',
    'neutral-500': 'focus:border-neutral-500',
    'neutral-600': 'focus:border-neutral-600',
    'neutral-700': 'focus:border-neutral-700',
    'neutral-800': 'focus:border-neutral-800',
    'neutral-900': 'focus:border-neutral-900',

    accent1: 'focus:border-accent1',
    'accent1-100': 'focus:border-accent1-100',
    'accent1-200': 'focus:border-accent1-200',
    'accent1-300': 'focus:border-accent1-300',
    'accent1-400': 'focus:border-accent1-400',
    'accent1-500': 'focus:border-accent1-500',
    'accent1-600': 'focus:border-accent1-600',
    'accent1-700': 'focus:border-accent1-700',
    'accent1-800': 'focus:border-accent1-800',
    'accent1-900': 'focus:border-accent1-900',

    'accent-2': 'focus:border-accent2',
    'accent-3': 'focus:border-accent3',
    
    'blackTransparent-03': 'focus:border-blackTransparent-03',
    'blackTransparent-06': 'focus:border-blackTransparent-06',
    'blackTransparent-10': 'focus:border-blackTransparent-10',
    'blackTransparent-20': 'focus:border-blackTransparent-20',
    'blackTransparent-30': 'focus:border-blackTransparent-30',
    'blackTransparent-40': 'focus:border-blackTransparent-40',
    'blackTransparent-50': 'focus:border-blackTransparent-50',
    'blackTransparent-60': 'focus:border-blackTransparent-60',
    'blackTransparent-70': 'focus:border-blackTransparent-70',
    'blackTransparent-80': 'focus:border-blackTransparent-80',
    'blackTransparent-90': 'focus:border-blackTransparent-90',
    
    'whiteTransparent-10': 'focus:border-whiteTransparent-10',
    'whiteTransparent-20': 'focus:border-whiteTransparent-20',
    'whiteTransparent-30': 'focus:border-whiteTransparent-30',
    'whiteTransparent-40': 'focus:border-whiteTransparent-40',
    'whiteTransparent-50': 'focus:border-whiteTransparent-50',
    'whiteTransparent-60': 'focus:border-whiteTransparent-60',
    'whiteTransparent-70': 'focus:border-whiteTransparent-70',
    'whiteTransparent-80': 'focus:border-whiteTransparent-80',
    'whiteTransparent-90': 'focus:border-whiteTransparent-90',

    '': '',
};