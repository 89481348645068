import { createEffect, createSignal, onMount } from 'solid-js';
import clsx from 'clsx';
import { mergeProps } from 'solid-js';

const defaultProps: ExpandableProps = {
    children: null,
    open: false,
    classes: '',
    disabled: false
};

export type ExpandableProps = {
    children: any;
    open: boolean;
    classes?: string;
    disabled?: boolean;
};

export const ExpandingContainer = (componentProps: ExpandableProps) => {
    const props = mergeProps(defaultProps, componentProps);
    const [initialHeight, setInitialHeight] = createSignal(0);
    let content: any;
    let wrapper: any;

    onMount(() => {
        setInitialHeight(content.getBoundingClientRect().height);
        if (initialHeight() !== 0 && !props.disabled) {
            wrapper.style.height = 0;
        }
    });

    createEffect(() => {
        setInitialHeight(content.getBoundingClientRect().height);

        if (props.open) {
            wrapper.style.height = (initialHeight().toString() as string) + 'px';
        } else {
            wrapper.style.height = 0;
        }
        
    });

    return (
        <div class={clsx({'overflow-hidden': !props.disabled}, 'transition-all', props.classes)} ref={wrapper}>
            <div ref={content}>
                {props.children}
            </div>
        </div>
    );
};
