type ErrorProps = {
    error?: ErrorObj
};

type ErrorObj = {
    message?: string,
    status?: number,
};

const parseErrorMessage = (error?: ErrorObj) => {
    if (!error || !error.message) {
        return 'Unknown Error';
    }

    return error.message;
};

export const ErrorComponent = (props: ErrorProps = {}) => {
    const message = parseErrorMessage(props.error);

    return (
        <div>
            <p>
                <strong>{message}</strong>
            </p>
        </div>
    );
};
