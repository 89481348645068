import clsx from 'clsx';
import { Match, Switch, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ImageSrcData, getBackgroundImageSrcData } from '../../components/image-component/image-component';
import { ColorName, bgColorClasses } from '../../style/color-classes';
import { ErrorCatcher } from '../../tools/error-catcher';
import { ColumnProps } from './column.types';
import { textColorClasses } from '../../style/color-classes';

export const Column = (props: ColumnProps) => {
    const verticalAlignmentClasses = clsx(
        props.positioning === 'row' ? 'flex flex-row items-center gap-12' : 'flex flex-col',
        { 'justify-start': props.verticalAlignment === 'top' },
        { 'justify-center': props.verticalAlignment === 'middle' },
        { 'justify-end': props.verticalAlignment === 'bottom' },
        { 'justify-between': props.verticalAlignment === 'spread' },
    );

    const horizontalAlignmentClasses = clsx(
        { 'items-center': props.horizontalAlignment === 'center' },
        { 'items-start': props.horizontalAlignment === 'left' },
        { 'items-end': props.horizontalAlignment === 'right' },
    );

    const commonPadding = 'tablet:px-8 laptop:px-12 desktop:px-20';
    const horizontalPaddingClasses = clsx(
        props.useHorizontalPadding && {
            [commonPadding]: !props.useHorizontalPaddingWhen || props.useHorizontalPaddingWhen === 'fromTabletUp',
            'px-5': !props.useHorizontalPaddingWhen || props.useHorizontalPaddingWhen === 'lessThanTablet',
            'px-0': props.useHorizontalPaddingWhen === 'fromTabletUp',
            'tablet:px-0': props.useHorizontalPaddingWhen === 'lessThanTablet',
        },
    );


    const paddingClasses = clsx(
        { 'rounded-l p-s tablet:p-s desktop:p-s': props.padding === 'small' },
        { 'rounded-l p-m tablet:p-m desktop:p-m': props.padding === 'medium' },
        { 'rounded-l p-s tablet:p-s desktop:pt-s': props.padding === 'medium' },
        { 'rounded-xl p-l tablet:p-l desktop:p-l': props.padding === 'large' },
        { 'rounded-xl p-m tablet:p-m desktop:pt-m': props.padding === 'large' },
    );

    const borderClasses = clsx(
        { 'border-solid border-2 border-black': props.border },
    );

    const textColorClass = props.textColor && textColorClasses[props.textColor as ColorName];

    const bgClassName = !props.image && props.backgroundValue && bgColorClasses[props.backgroundValue as ColorName];

    const paddingExtraLeft = { 'desktop:pl-2': props.extendBgColor && props.extendBgColorSide === 'right' && props.paddingLeft === '-' }; // half of the grid gap

    const BackgroundExpanders = () => (
        <Switch>
            <Match when={props.extendBgColor && props.extendBgColorSide === 'left'}>
                {/* on mobile we want to extend background color to the right side aswell */}
                <div class={clsx('tablet:hidden absolute left-full top-0 h-full w-[1000%]', bgClassName)}></div>
                <div class={clsx('absolute right-full top-0 h-full w-[1000%]', bgClassName)}></div>
            </Match>
            <Match when={props.extendBgColor && props.extendBgColorSide === 'right'}>
                <div class={clsx('tablet:hidden absolute right-full top-0 h-full w-[1000%]', bgClassName)} />
                <div class={clsx('absolute left-full top-0 h-full w-[1000%]', bgClassName)} />
            </Match>
        </Switch>
    );

    return (
        <ErrorCatcher componentName="Column">
            <div
                data-component-name="Column"
                data-identifier="Column"
                class={clsx(
                    'mb-8',
                    'tablet:mb-0',
                    'relative',
                    bgClassName,
                    verticalAlignmentClasses,
                    horizontalAlignmentClasses,
                    textColorClass,
                    horizontalPaddingClasses,
                    paddingClasses,
                    borderClasses,
                    '[&>*:last-child]:!mb-0',
                    '[&>*:first-child]:!mt-0',
                    { 'tablet:order-none order-first': props.columnFirst },
                )}
            >
                <BackgroundExpanders />
                {props.children}
            </div>
        </ErrorCatcher>
    );
};

Column.parseProps = (atts: any) => {
    return {
        horizontalAlignment: atts.horizontalAlignment,
        verticalAlignment: atts?.verticalAlignment,
        backgroundValue: atts.backgroundColor ? atts.backgroundColor.name : undefined,
        textColor: atts.textColor ? atts.textColor.name : undefined,
        roundCorners: atts.roundCorners,
        image: atts.image,
        extendBgColor: !!atts.backgroundColor && atts.extendBgColor,
        extendBgColorSide: !!atts.backgroundColor && atts.extendBgColorSide,
        combineWithRightColumn: atts.combineWithRight,
        redDividerEnabled: atts.redDividerEnabled,
        overlayType: atts.overlayType,
        flowWild: atts.flowWild,
        paddingTop: atts.paddingTop,
        paddingBottom: atts.paddingBottom,
        paddingLeft: atts.paddingLeft,
        paddingRight: atts.paddingRight,
        offsetOption: atts.offsetOption,
        positioning: atts.positioning,
        useMobileSpacingOverrides: atts.useMobileSpacingOverrides,
        mobilePaddingTop: atts.mobilePaddingTop,
        mobilePaddingBottom: atts.mobilePaddingBottom,
        mobilePaddingLeft: atts.mobilePaddingLeft,
        mobilePaddingRight: atts.mobilePaddingRight,
        useHorizontalPadding: atts.useHorizontalPadding ? atts.useHorizontalPadding : false,
        useHorizontalPaddingWhen: atts.useHorizontalPaddingWhen,
        columnFirst: atts.columnFirst,
        padding: atts.padding,
        border: atts.border,
    };
};
