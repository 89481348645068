import clsx from 'clsx';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Menu } from '../menu/menu';
import { createEffect, onMount } from 'solid-js';

export const HeaderContainer = () => {
    const headerClasses = clsx('sticky', 'top-0', 'w-full', { 'z-50': true }, 'backdrop-blur');
    return (
        <ErrorCatcher componentName="Header">
            <header class={clsx(headerClasses)}>
                {/* TODO: fix props */}
                <Menu />
            </header>
        </ErrorCatcher>
    );
};
