import { createSignal } from 'solid-js';
import { FieldType } from '../forms.d';
import clsx from 'clsx';

export const FormTextArea = (props: FieldType) => {
    const [isFocused, setIsFocused] = createSignal(false);

    const handleInputFocus = () => {
        setIsFocused(true);
        inputRef.focus();
    };

    const handleInputFocusOut = () => {
        if (props.formValues()[props.slug] === '') {
            setIsFocused(false);
        }
    };

    const getInputClasses = () => clsx(
        'outline-none',
        'bg-secondary-800',
        'top-[0.7rem]',
        'self-start',
        'w-[90%]',
        'left-s',
        'resize-none',
        {'relative': isFocused()},
        {'top-xxs': isFocused()},
    );

    const inputRef :any = 
        <textarea
            rows={5}
            class={getInputClasses()}
            onFocus={handleInputFocus}
            onBlur={handleInputFocusOut}
            required={props.required}
            value={props.formValues()[props.slug] === undefined ? '' : props.formValues()[props.slug]}
            onChange={((e:any) => props.handleInputChange(props.slug, e.currentTarget.value))}
        />;

    const fieldData =  props.slug;
    if (fieldData) {
        return (
            <div class={`${props.inputClasses} relative rounded-s w-full`}>
                <label class={isFocused() ? props.labelFocusClasses : props.labelIdleClasses} for={props.slug}>{props.name} {props.required && '*'}</label>
                {inputRef}
            </div>
        );
    }

};

