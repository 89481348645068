import { A, useLocation } from '@solidjs/router';
import clsx from 'clsx';
import { createEffect, createSignal, Show, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { bgColorClasses, ColorName, textColorClasses } from '../../../style/color-classes';
import { HamburgerMenuIcon } from '../../icons-library/hamburger-menu';
import { SiteLogo } from '../../icons-library/site-logo';
import { MenuProps } from '../menu-types';
import { MobileSlideMenu } from './mobile-slide-menu';
import { MobileSearch } from '../../search/search';

export const MenuMobile = (props: MenuProps) => {
    const { pageTheme } = useContext(AppContext);
    const [mobileMenuActive, setMobileMenuActive] = createSignal(false);
    const [searchActive, setSearchActive] = createSignal(false);
    const [colorClasses, setColorClasses] = createSignal('');

    const location = useLocation();

    const toggleMenu = () => {
        const body = document.querySelector('body');
        body?.classList.toggle('fixed');
        setMobileMenuActive(!mobileMenuActive());
    };

    const closeMenu = () => {
        const body = document.querySelector('body');
        body?.classList.remove('fixed');
        setMobileMenuActive(false);
    };

    createEffect(() => {
        if (location.pathname) {
            closeMenu();
        }

        let myColorClasses = textColorClasses[pageTheme()?.colors.textColor as ColorName];
        myColorClasses += pageTheme().transparentSiteHeader ? '' : bgColorClasses[pageTheme()?.colors.bgColor as ColorName];
        setColorClasses(myColorClasses);
    });

    const navClasses = clsx('flex', 'justify-between', 'items-center', 'gap-5', colorClasses());

    return (
        <>
            <div data-identifier="mobile-header" class={navClasses}>
                <A aria-label="Gå till startsidan" href="/" class="p-s block">
                    <SiteLogo width="5.31rem" height="2rem" />
                </A>

                <button aria-label="Visa meny" onClick={toggleMenu} class="p-s">
                    <HamburgerMenuIcon />
                </button>
            </div>

            <div
                class={clsx(
                    'fixed',
                    'backdrop-blur-lg',
                    'top-0',
                    'bottom-0',
                    'left-0',
                    'right-0',
                    { 'hidden ': !mobileMenuActive() },
                    'animate-fade-in-move-up',
                )}
            >
                <Show when={mobileMenuActive()}>
                    <div class="overflow-y-hidden p-4">
                        <Show when={!searchActive()} fallback={<MobileSearch setSearchActive={setSearchActive} setMobileMenuActive={setMobileMenuActive} />}>
                            <MobileSlideMenu setSearchActive={setSearchActive} menuItems={props.primaryMenuData} setMobileMenuActive={setMobileMenuActive} />
                        </Show>
                    </div>
                </Show>
            </div>
        </>
    );
};
