// This is a list of objects where the key in the outermost
// objects is the index of the child column. They then contain
// the different span classes that might be needed.
// We only have three indexes because that is the max amount of
// children in one section.
export const gridTemplateColumnChildClasses = [
    {
        '1/4': '[&>*:nth-child(1)]:col-span-12 tablet:[&>*:nth-child(1)]:col-span-3 desktop:[&>*:nth-child(1)]:col-span-3',
        '1/3': '[&>*:nth-child(1)]:col-span-12 tablet:[&>*:nth-child(1)]:col-span-4 desktop:[&>*:nth-child(1)]:col-span-4',
        '1/2': '[&>*:nth-child(1)]:col-span-12 tablet:[&>*:nth-child(1)]:col-span-6 desktop:[&>*:nth-child(1)]:col-span-6',
        '2/3': '[&>*:nth-child(1)]:col-span-12 tablet:[&>*:nth-child(1)]:col-span-6 desktop:[&>*:nth-child(1)]:col-span-8',
        '1/1': '[&>*:nth-child(1)]:col-span-12 tablet:[&>*:nth-child(1)]:col-span-12 desktop:[&>*:nth-child(1)]:col-span-12',
    },
    {
        '1/4': '[&>*:nth-child(2)]:col-span-12 tablet:[&>*:nth-child(2)]:col-span-3 desktop:[&>*:nth-child(2)]:col-span-3',
        '1/3': '[&>*:nth-child(2)]:col-span-12 tablet:[&>*:nth-child(2)]:col-span-4 desktop:[&>*:nth-child(2)]:col-span-4',
        '1/2': '[&>*:nth-child(2)]:col-span-12 tablet:[&>*:nth-child(2)]:col-span-6 desktop:[&>*:nth-child(2)]:col-span-6',
        '2/3': '[&>*:nth-child(2)]:col-span-12 tablet:[&>*:nth-child(2)]:col-span-6 desktop:[&>*:nth-child(2)]:col-span-8',
        '1/1': '[&>*:nth-child(2)]:col-span-12 tablet:[&>*:nth-child(2)]:col-span-12 desktop:[&>*:nth-child(2)]:col-span-12',
    },
    {
        '1/4': '[&>*:nth-child(3)]:col-span-12 tablet:[&>*:nth-child(3)]:col-span-3 desktop:[&>*:nth-child(3)]:col-span-3',
        '1/3': '[&>*:nth-child(3)]:col-span-12 tablet:[&>*:nth-child(3)]:col-span-4 desktop:[&>*:nth-child(3)]:col-span-4',
        '1/2': '[&>*:nth-child(3)]:col-span-12 tablet:[&>*:nth-child(3)]:col-span-6 desktop:[&>*:nth-child(3)]:col-span-6',
        '2/3': '[&>*:nth-child(3)]:col-span-12 tablet:[&>*:nth-child(3)]:col-span-6 desktop:[&>*:nth-child(3)]:col-span-8',
        '1/1': '[&>*:nth-child(3)]:col-span-12 tablet:[&>*:nth-child(3)]:col-span-12 desktop:[&>*:nth-child(3)]:col-span-12',
    },
];
