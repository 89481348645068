import { useLocation } from '@solidjs/router';
import { gql } from 'graphql-request';
import { Match, Switch, createEffect, createSignal, useContext } from 'solid-js';
import tailwindConfig from '../../../tailwind.config';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { PageColorThemeName } from '../../style/color-classes';
import { ErrorCatcher } from '../../tools/error-catcher';
import { GET_PAGE_QUERY } from '../page/page';
import { MenuDesktop } from './menu-desktop/menu-desktop';
import { MenuMobile } from './menu-mobile/menu-mobile';
import { MenuItem } from './menu-types';

const GET_MENUS_QUERY = gql`
    query GetPrimaryMenu($type: [String]) {
        menu: resources(type: $type) {
            content
            key
        }
    }
`;

export const Menu = () => {
    const { createCachedResource, viewport } = useContext(AppContext);
    const location = useLocation();
    const [primaryContentData, setPrimaryContentData] = createSignal<MenuItem[]>([] as MenuItem[]);
    const [secondaryMenuContent, setSecondaryMenuContent] = createSignal<MenuItem[]>([] as MenuItem[]);
    const [pageContent] = createCachedResource(GET_PAGE_QUERY, () => ({ key: location.pathname }), true);

    const hasHero = pageContent()?.page?.content?.blocks[0].blockName === 'apelsin/hero';
    const pageTheme = pageContent()?.page?.content?.page_settings?.page_theme || PageColorThemeName.Light;
    const [isDarkMenu, setIsDarkMenu] = createSignal((pageTheme === PageColorThemeName.Light || pageTheme === PageColorThemeName.Primary) && !hasHero);

    const [menuContent] = createCachedResource(GET_MENUS_QUERY, () => ({ type: ['menu'] }), true);

    const setMenuData = () => {
        menuContent()?.menu.forEach((menu: any) => {
            if (menu.content.externalId === 'menus-primary_menu') {
                setPrimaryContentData(menu.content.menu);
            }
            if (menu.content.externalId === 'menus-secondary_menu') {
                setSecondaryMenuContent(menu.content.menu);
            }
        });
    };


    setMenuData();

    const isBigTablet = () => {
        if (viewport.width >= tailwindConfig.theme.extend.screenValues.bigTabletValue) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <ErrorCatcher componentName="Menu">
            <Switch>
                <Match when={isBigTablet()}>
                    <MenuDesktop primaryMenuData={primaryContentData()} secondaryMenuData={secondaryMenuContent()} />
                </Match>
                <Match when={!isBigTablet()}>
                    <MenuMobile primaryMenuData={primaryContentData().concat(secondaryMenuContent())} />
                </Match>
            </Switch>
        </ErrorCatcher>
    );
};
