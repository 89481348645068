import { Link } from '@solidjs/router';
import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Heading } from '../../ui-components/heading/heading';
import { WrappedImage } from '../image-component/wrapped-image';
import { NewsPostIntroProps } from './news-post-intro.d';
import dayjs from 'dayjs';

export const NewsPostIntroContainer = (props: NewsPostIntroProps) => {
    const AppState = useContext(AppContext);

    return (
        <ErrorCatcher componentName="NewsPost">
            <section class="px-containerPadding">
                <div class="tablet:mb-16 mx-auto mb-4 max-w-fit">
                    <div class="mb-8">
                        <Heading disableMaxWidth={true} tag="h1" classes="mt-0" fontSize="xxl">
                            {props.postTitle}
                        </Heading>
                    </div>

                    <div class="mb-xl flex w-full flex-wrap justify-center gap-4">
                        {AppState.pageNewsArchiveUrl && (
                            <Link
                                class="border-1 border-secondary-600 px-s text-secondary-900 hover:bg-secondary-600 hover:text-secondary-100 text-medium inline-block rounded-l py-[0.31rem] transition-colors duration-200"
                                href={AppState.pageNewsArchiveUrl}
                            >
                                Press & nyhet
                            </Link>
                        )}
                        {props.date && (
                            <span class="border-1 border-secondary-600 px-s text-secondary-100 bg-secondary-600 text-medium inline-block rounded-l py-[0.31rem]">
                                {dayjs(new Date(props.date)).format('D MMMM YYYY')}
                            </span>
                        )}
                    </div>

                    <div class="tablet:mb-12 mb-4">
                        {props.featuredImage && (
                            <WrappedImage
                                image={props.featuredImage}
                                aspectRatio="original"
                                renderWidth="100%"
                                renderHeight="auto"
                                width="800px"
                                height="auto"
                            />
                        )}
                    </div>
                </div>
            </section>
        </ErrorCatcher>
    );
};
