import { LogoType } from '../logos-grid';
import { ErrorCatcher } from '../../../tools/error-catcher';
import clsx from 'clsx';
import { WrappedImage } from '../../image-component/wrapped-image';

type Props = {
    logo: LogoType;
    index: number;
};

const transparencyClasses: Record<string, string> = {
    '.75': 'opacity-[.75]',
    '.9': 'opacity-[.9]',
};

export const Logo = (props: Props) => {
    const random = ['.75', '.9'];
    const randomOpacity = random[Math.floor(Math.random() * random.length)];
    const randomOpacityClass = transparencyClasses[randomOpacity];

    return (
        <ErrorCatcher componentName="Logo">
            <div
                class={clsx(
                    'flex',
                    'items-center',
                    'bg-white',
                    'aspect-square',
                    'rounded-m',
                    'mobile:rounded-l',
                    'transform',
                    'hover:scale-105',
                    'transition-all',
                    'ease-in',
                    'duration-30',
                    { 'rotate-[-2.26deg]': props.index === 0 },
                    { 'rotate-[5.38deg]': props.index === 1 },
                    { 'rotate-[4.98deg]': props.index === 4 },
                    { 'rotate-[-7.07deg]': props.index === 5 },
                    { 'rotate-[6.86deg]': props.index === 6 },
                    'hover:rotate-0',
                    randomOpacityClass,
                    { '-mt-8': props.index === 1 || props.index === 4 || props.index === 7 },
                    { '-mt-1': props.index === 2 || props.index === 5 || props.index === 8 },
                    'hover:opacity-100',
                )}
            >
                <WrappedImage image={props.logo.image} aspectRatio="original" roundCorners="l" width="15px" height="auto" />
            </div>
        </ErrorCatcher>
    );
};
