import { For, Show, createEffect, createResource, createSignal, useContext } from 'solid-js';
import { gql } from 'graphql-request';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import clsx from 'clsx';
import { Heading } from '../../ui-components/heading/heading';
import { ColorName } from '../../style/color-classes';
import { ReadMoreItem } from './read-more-item/read-more-item';

export type ReadMoreProps = {
    selectedPosts: [
        {
            id: number;
            type: string;
            slug: string;
            permaLink: string;
        },
    ];
    title: string;
    index: number;
    blockName: string;
    hash: string;
};

const GET_POSTS_QUERY = gql`
    query GetBlogPosts($keys: [String]) {
        posts: resources(keys: $keys, order: ASC) {
            content
        }
    }
`;

export const ReadMore = (componentProps: ReadMoreProps) => {

    const { createCachedResource } = useContext(AppContext);
    const [postsContent] = createCachedResource(GET_POSTS_QUERY, () => ({ keys: componentProps.selectedPosts.map((post) => post.permaLink) }), true);

    const loadingClasses = clsx('min-h-[200rem]');
    return (
        <ErrorCatcher componentName="ReadMoreArticles">
            <Show when={postsContent()} fallback={<div class={loadingClasses}></div>}>
                <div class="tablet:flex-row gap-y-s tablet:gap-x-m flex flex-col justify-center">
                    <For each={postsContent().posts}>
                        {(post) => {
                            return (
                                <div class="mb-xl">
                                    <ReadMoreItem readMore={true} post={post.content} />
                                </div>
                            );
                        }}
                    </For>
                </div>
            </Show>
        </ErrorCatcher>
    );
};

export default ReadMore;
