import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    height: 31,
    width: 34,
    viewBox: '0 0 31 34'
};

export const ChatBubbleArrowIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M33.3262 30.1747L33.3261 0.12439H0.673801L33.3262 30.1747Z"
            />

        </SvgIcon>
    );
};
