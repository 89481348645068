import { clsx } from 'clsx';
import { Show, mergeProps } from 'solid-js';
import { ColorName, ThemeName, sectionColorThemeClasses } from '../../style/color-classes';
import { ErrorCatcher } from '../../tools/error-catcher';
import { DividerType, SectionDivider } from '../section-divider/section-divider';
import { gridTemplateColumnChildClasses } from './grid-template-column-child-classes';
import { getResponsiveOverrideClasses } from './responsive-overrides/responsive-overrides';
import { GetChildColumnClassesProps, GridTemplateColumnClassObject, SectionProps } from './section-types';

const defaultProps = {
    removeGaps: false,
    useCustomContentMaxWidth: false,
    postType: 'page',
};

export const Section = (componentProps: SectionProps) => {
    const props = mergeProps(defaultProps, componentProps);
    // For the children of the section, in other words the columns, we need
    // to loop through them and apply the correct grid column span classes.
    const childColumnClasses = (args: GetChildColumnClassesProps) => {
        const classes: string[] = [];

        // we need to assign grid start to the first child.
        classes.push('[&>*:nth-child(1)]:col-start-1');

        for (let index = 0; index < args.childCount; index++) {
            const spanFraction = args.template[index % args.template.length].width;
            classes.push(gridTemplateColumnChildClasses[index][spanFraction as keyof GridTemplateColumnClassObject]);
        }

        return classes;
    };

    const colorTheme = props?.backgroundType === 'color' && props.backgroundValue && sectionColorThemeClasses[props.backgroundValue as ThemeName];
    const colorClasses = colorTheme ? colorTheme.classes : null;

    const paddingClasses = clsx(
        {
            'pt-sectionSpacingS': props.paddingTop === 's',
        },
        {
            'pt-sectionSpacingM': props.paddingTop === 'm',
        },
        {
            'pt-sectionSpacingL': props.paddingTop === 'l',
        },
        {
            'pt-sectionSpacingXL': props.paddingTop === 'xl',
        },

        {
            'pb-sectionSpacingS': props.paddingBottom === 's',
        },
        {
            'pb-sectionSpacingM': props.paddingBottom === 'm',
        },
        {
            'pb-sectionSpacingL': props.paddingBottom === 'l',
        },
        {
            'pb-sectionSpacingXL': props.paddingBottom === 'xl',
        },
    );

    const marginClasses = clsx(
        {
            'mt-sectionSpacingS': props.marginTop === 's',
        },
        {
            'mt-sectionSpacingM': props.marginTop === 'm',
        },
        {
            'mt-sectionSpacingL': props.marginTop === 'l',
        },
        {
            'mt-sectionSpacingXL': props.marginTop === 'xl',
        },

        {
            'mb-sectionSpacingS': props.marginBottom === 's',
        },
        {
            'mb-sectionSpacingM': props.marginBottom === 'm',
        },
        {
            'mb-sectionSpacingL': props.marginBottom === 'l',
        },
        {
            'mb-sectionSpacingXL': props.marginBottom === 'xl',
        },
    );

    return (
        <ErrorCatcher componentName="Section">
            <div data-identifier="Section" class={clsx(colorClasses, 'relative')} id={props.sectionIdentifier}>
                <Show when={props.topDividerEnabled}>
                    <SectionDivider
                        fill={colorTheme?.bgColor as ColorName}
                        topDivider={props.topDivider as DividerType}
                        topDividerFlipHorisontally={props?.topDividerFlipHorisontally}
                    />
                </Show>

                <div class={clsx('px-containerPadding', 'w-full', paddingClasses, marginClasses, getResponsiveOverrideClasses(props.responsiveOverrides))}>
                    <div
                        class={clsx(
                            {
                                'max-w-blogPost': props?.postType === 'blog' && props.children.length === 1 && props.useCustomContentMaxWidth === false,
                            },
                            {
                                'max-w-newsPost': props?.postType === 'news' && props.children.length === 1 && props.useCustomContentMaxWidth === false,
                            },
                            {
                                'max-w-projectPost': props?.postType === 'project' && props.children.length === 1 && props.useCustomContentMaxWidth === false,
                            },
                            {
                                'max-w-maximal':
                                    (props.postType === 'page' && props.children.length === 1 && props.useCustomContentMaxWidth === false) ||
                                    (props.children.length > 1 && props.useCustomContentMaxWidth === false) ||
                                    (props.useCustomContentMaxWidth === true && !props?.customContentMaxWidthValue),
                            },
                            { 'max-w-fit': props.useCustomContentMaxWidth === true && props.customContentMaxWidthValue === 'fit' },
                            { 'max-w-wide': props.useCustomContentMaxWidth === true && props.customContentMaxWidthValue === 'wide' },
                            { 'max-w-maximal': props.useCustomContentMaxWidth === true && props.customContentMaxWidthValue === 'maximal' },
                            { 'max-w-fullScreen': props.useCustomContentMaxWidth === true && props.customContentMaxWidthValue === 'fullScreen' },
                            'mx-auto w-full',
                            'grid',
                            { 'tablet:gap-x-12 bigTablet:gap-x-8 laptop:gap-x-12 desktop:gap-x-16': !props.removeGaps },
                            'grid-cols-main',
                            childColumnClasses({
                                childCount: props.children.length,
                                template: props.template,
                            }),
                        )}
                    >
                        {props.children}
                    </div>
                </div>

                <Show when={props.bottomDividerEnabled}>
                    <SectionDivider
                        fill={colorTheme?.bgColor as ColorName}
                        bottomDivider={props.bottomDivider as DividerType}
                        bottomDividerFlipHorisontally={props?.bottomDividerFlipHorisontally}
                    />
                </Show>
            </div>
        </ErrorCatcher>
    );
};

Section.parseProps = (atts: any) => {
    return {
        backgroundType: atts.backgroundType,
        backgroundValue: atts.backgroundValue,
        template: atts.template,
        overlayType: atts.overlayType,
        topDividerEnabled: atts.topDividerEnabled,
        bottomDividerEnabled: atts.bottomDividerEnabled,
        topDivider: atts.topDivider,
        bottomDivider: atts.bottomDivider,
        topDividerFlipHorisontally: atts.topDividerFlipHorisontally,
        bottomDividerFlipHorisontally: atts.bottomDividerFlipHorisontally,
        marginTop: atts.marginTop,
        marginBottom: atts.marginBottom,
        paddingTop: atts.paddingTop,
        paddingBottom: atts.paddingBottom,
        responsiveOverrides: atts.responsiveOverrides,
        removeGaps: atts.removeGaps,
        useCustomContentMaxWidth: atts.useCustomContentMaxWidth,
        customContentMaxWidthValue: atts.customContentMaxWidthValue,
        sectionIdentifier: atts.sectionIdentifier,
    };
};
