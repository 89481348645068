import { Router } from '@solidjs/router';
import { GraphQLClient } from 'graphql-request';
import { hydrate } from 'solid-js/web';

import { MetaProvider } from 'solid-meta';
import { AppContextProvider } from '../isomorphic/app-context-provider/app-context-provider';
import { App } from '../isomorphic/app/app';
import { AppState } from '../isomorphic/types/app-state';

// Get the appstate from the SSR
const appState: AppState = JSON.parse(document.getElementById('_appstate')!.innerText);



const graphqlClient = (query: string, variables: any, headers: any) => {

    const client = new GraphQLClient('/api', {headers: headers});
    return client.request(query, variables, headers);
};

hydrate(
    () => (
        // REMINDER: We have a boot-server as well where stuff has to be added
        <AppContextProvider
            imagesServiceUrl={appState.settings.imagesServiceUrl}
            client={graphqlClient}
            cache={appState.cache}
            supportedImageFormats={appState.settings.supportedImageFormats}
            viewport={appState.settings.viewport}
            mobile={appState.settings.mobile}
            page404url={appState.settings.generalOptions?.page404url}
            pageBlogArchiveUrl={appState.settings.generalOptions?.pageBlogArchiveUrl}
            pageNewsArchiveUrl={appState.settings.generalOptions?.pageNewsArchiveUrl}
            siteLogo={appState.settings.generalOptions?.siteLogo}
            siteInfo={appState.settings.siteInfo}
            setStatusCode={() => {
                /**/
            }}
        >
            <Router url={window.location.pathname}>
                <MetaProvider>
                    <App />
                </MetaProvider>
            </Router>
        </AppContextProvider>
    ),

    document.getElementById('root')!
);
