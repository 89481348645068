type NavigationProps = {
    event?: any;
    id?: string;
    smallScreen: boolean;
};

export const navigateToAnchor = (props: NavigationProps ) => {
    if (!props.id) {
        return;
    }

    const parsedId = props.id.includes('#') ? props.id.replace('#', '') : props.id;

    if (props.event) {
        props.event.preventDefault();
    }

    const element = document.getElementById(parsedId);
    if (!element) return;

    window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
    });
};