import { ButtonProps } from './button.d';
import { ErrorCatcher } from '../../tools/error-catcher';
import clsx from 'clsx';
import { ButtonArrowRightIcon } from '../../components/icons-library/button-arrow-right';
import { Show } from 'solid-js';
import { A, Link } from '@solidjs/router';

export const Button = (componentProps: ButtonProps) => {
    const props = componentProps as ButtonProps;

    const buttonClasses = () =>
        clsx(
            { 'bg-primary text-primary-100 focus:bg-primary-600 hover:bg-primary-600': props.color === 'primary' && props.variant === 'solid' },
            { 'bg-secondary-600 text-secondary-100 focus:bg-secondary-700 hover:bg-secondary-700': props.color === 'secondary' && props.variant === 'solid' },
            { 'bg-white text-neutral-900 focus:bg-neutral-100 hover:bg-neutral-100': props.color === 'white' && props.variant === 'solid' },
            { 'border-2': props.variant === 'ghost' },
            { 'border-primary text-primary': props.variant === 'ghost' && props.color === 'primary' },
            { 'border-secondary-700 text-secondary-700': props.variant === 'ghost' && props.color === 'secondary' },
            { 'border-white text-white': props.variant === 'ghost' } && props.color === 'white',
            { 'py-4 px-6 text-buttonL': props.buttonSize === 'large' },
            { 'py-3 px-5 text-buttonS': props.buttonSize === 'small' },
            'rounded-s group inline-flex items-center font-medium',
            { 'mt-8': props.margin === 'top' },
            { 'mb-8': props.margin === 'bottom' },
            { 'my-8': props.margin === 'topAndBottom' },
            { 'w-full tablet:w-[20rem]': props.fullWidth },
            { 'justify-center': props.fullWidth },
        );

    const arrow = (
        <ButtonArrowRightIcon
            width={props.buttonSize === 'large' ? '1rem' : '0.64581rem'}
            height={props.buttonSize === 'large' ? '1rem' : '0.58331rem'}
            class={clsx(
                'animate-move-right-back',
                'group-hover:animate-move-right group-focus:animate-move-right',
                'ml-4',
                { 'stroke-white': props.variant !== 'ghost' && props.color !== 'white' },
                { 'stroke-primary': props.variant === 'ghost' && props.color === 'primary' },
                { 'stroke-secondary-700': props.variant === 'ghost' && props.color === 'secondary' },
            )}
        />
    );

    const handler = () => {
        if (props.onClick) props.onClick();
    };

    return (
        <ErrorCatcher componentName="Button">
            <div class={clsx({ 'm-auto flex w-full justify-center': props.fullWidth })}>
                <Show
                    when={props.url}
                    fallback={
                        <button class={buttonClasses()} onClick={() => handler()}>
                            {props.label}
                            {props.arrow && arrow}
                        </button>
                    }
                >
                    <Show
                        when={props.url?.startsWith('http') || props.url?.startsWith('mailto')}
                        fallback={
                            <A href={props.url ? props.url : '/'} class={buttonClasses()} id={props.id}>
                                {props.label}
                                {props.arrow && arrow}
                            </A>
                        }
                    >
                        <a href={props.url} target="_blank" class={buttonClasses()} id={props.id}>
                            {props.label}
                            {props.arrow && arrow}
                        </a>
                    </Show>
                </Show>
            </div>
        </ErrorCatcher>
    );
};

Button.parseProps = (atts = {}) => {
    return atts;
};
