import { A } from '@solidjs/router';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Heading } from '../../ui-components/heading/heading';
import { WrappedImage } from '../image-component/wrapped-image';

export const NewsListItem = (props: any) => {
    const wrapperClasses = clsx(
        'rounded-m',
        'bg-white',
        'tablet:bg-opacity-40',
        'flex flex-col',
        'tablet:flex-row',
        'tablet:items-center',
        'tablet:h-full',
        'tablet:hover:bg-white',
        'tablet:transition-all',
    );
    
    return (
        <ErrorCatcher componentName="BlogListItem">
            <A class="group" href={props.news.permalink}>
                <div class={wrapperClasses}>
                    <div class="mb-xs tablet:mb-0 tablet:basis-2/5">
                        {props.news.featured_image && (
                            <div class="h-full">
                                <WrappedImage
                                    width={300}
                                    renderWidth={'100%'}
                                    height={'100%'}
                                    aspectRatio={'newsListImage'}
                                    image={props.news.featured_image}
                                    roundCorners={'m'}
                                    topRounding={props.isMobile}
                                    noRoundCornersRightSide={props.isTablet}
                                />
                            </div>
                        )}
                    </div>

                    <div class={'mobile:pb-xs px-xs tablet:text-left tablet:pl-l tablet:pr-l tablet:basis-3/5 text-center'}>
                        <Heading classes="mb-[0.25rem] pb-s " fontSize="l" tag="h3">
                            {props.news.preamble}
                        </Heading>
                        <span class="border-1 border-secondary-600 px-s text-secondary-100 bg-secondary-600 text-medium mb-s inline-block rounded-l py-[0.31rem]">
                            {dayjs(new Date(props.news.date)).format('D MMMM YYYY')}
                        </span>
                    </div>
                </div>
            </A>
        </ErrorCatcher>
    );
};

export type BlogListItemProps = {
    post: {
        featured_image: string;
        permalink: string;
        post_title: string;
        list_description: string;
    };
};
