import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    height: 32,
    width: 125,
    fill: 'currentColor',
    viewBox: '0 0 125 32',
};

export const SiteLogo = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <g
                id="layer1"
                transform="translate(-634.2063,3576.272)"
            >
                <path
                    id="path1-9"
                    d="m 649.68414,-3568.7129 7.06624,16.9912 c 0,0 0.27706,9e-4 0.28887,0 0.0197,0.01 0.0359,0.022 0.0574,0.028 l 4.31699,-0.019 3.39204,-7.7169 3.39153,7.7153 c 0.01,-0.01 0.0173,-0.015 0.0269,-0.023 l 0.009,0.024 2.34463,0.107 c 2.65076,0.121 2.41814,0.026 2.41814,0.026 l 7.03005,-17.1422 -6.41091,-0.01 -3.02694,7.7403 -3.21224,-7.7368 -5.11929,-5e-4 -3.19905,7.437 -2.96829,-7.4254 z" />
                <path
                    d="m 643.26989,-3551.7831 c -1.83153,-0.5655 -3.29739,-1.9735 -4.19292,-3.6964 -0.35896,-0.6905 -0.59375,-1.7649 -0.62552,-4.2333 l -0.0458,-3.562 h -4.19935 v -2.801 -2.6633 h 4.23333 l 0.002,-3.5461 6.33402,-3.5718 0.0144,7.1179 h 2.88118 l 2.13348,5.4498 -5.02449,0.01 0.01,2.366 c 0.009,2.0936 0.20394,2.0873 0.71616,2.7792 0.42402,0.5728 4.28988,0.4009 4.28988,0.4009 l -0.015,5.9993 z"
                    id="path1-9-4" />
                <path
                    style="display:inline;;fill-opacity:1;stroke:none;stroke-width:8;stroke-linecap:square;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke fill markers"
                    d="m 687.65581,-3568.6108 c -4.66136,0 -8.49964,3.8383 -8.49964,8.4997 0,4.6613 3.83828,8.4996 8.49964,8.4996 4.66136,0 8.50136,-3.8383 8.50136,-8.4996 0,-4.6614 -3.84,-8.4997 -8.50136,-8.4997 z m 0,5.5817 c 1.64423,0 2.91794,1.2738 2.91794,2.918 0,1.6442 -1.27371,2.9179 -2.91794,2.9179 -1.64423,0 -2.91622,-1.2737 -2.91622,-2.9179 0,-1.6442 1.27199,-2.918 2.91622,-2.918 z"
                    id="path2-7" />
                <g
                    id="g15-3"
                    transform="matrix(0.8793325,0,0,0.8793325,687.72257,-3589.0903)"
                >
                    <path
                        d="m 51.049777,42.616957 c -2.383268,0.02752 -4.660557,-2.107735 -5.402976,-3.487566 -0.601845,-1.118576 -0.430819,-1.663864 -0.519022,-8.305016 l 0.01684,-7.51271 h 6.6123 l 0.034,6.25139 c 0.01956,3.388855 0.165568,6.152906 0.342532,6.483555 0.80527,1.504686 3.199723,1.207961 4.008666,-0.496752 0.379197,-0.799106 0.475679,-2.106962 0.475679,-6.44821 l 0.02236,-5.789983 h 6.552516 l -0.0224,19.332847 H 60.01092 c -2.701208,0 -3.159362,-0.07017 -3.159362,-0.483526 0,-0.344966 -0.150888,-0.419545 -0.526559,-0.260254 -1.581695,0.74378 -2.750951,0.74378 -5.275222,0.716225 z m 14.293962,-9.332591 v -9.972701 h 3.309807 3.354703 v 1.814763 c 1.798592,-0.921779 3.422176,-1.441696 4.844596,-1.610028 l 1.730119,-0.204735 v 3.502168 2.174013 c -2.625674,0.429451 -2.8465,0.476043 -4.35378,1.282723 -0.430065,0.230163 -1.081968,0.900876 -1.448656,1.490471 -0.605413,0.973421 -0.676433,1.535427 -0.77229,6.111921 l 1.1e-5,4.771551 h -6.664487 z"
                        id="path1-7-6" />
                    <path
                        d="m 33.791542,23.3125 c -5.301018,0 -9.666016,4.364998 -9.666016,9.666016 0,5.301017 4.364998,9.666015 9.666016,9.666015 5.301017,0 9.666016,-4.364998 9.666015,-9.666015 0,-5.301018 -4.364998,-9.666016 -9.666015,-9.666016 z m 0,6.347656 c 1.869866,0 3.316406,1.448494 3.316406,3.31836 0,1.869865 -1.44654,3.316406 -3.316406,3.316406 -1.869866,0 -3.31836,-1.446541 -3.31836,-3.316406 0,-1.869866 1.448494,-3.31836 3.31836,-3.31836 z"
                        id="path2-5-12" />
                    <path
                        d="m 13.425583,29.665589 h -2.326276 v -3.159362 -3.159351 h 1.163132 1.163144 V 21.24734 c 0,-2.557975 1.32888,-4.493932 3.158225,-5.655154 1.598831,-1.014894 1.644384,-1.014906 5.711458,-1.014906 h 4.835507 l -0.01474,5.911086 h -2.623445 c -2.884676,0 -3.674008,0.42762 -3.845718,1.955791 7e-6,0.902719 6e-6,0.902719 6e-6,0.902719 h 2.407128 l -10e-7,3.176625 v 3.142066 h -2.407132 v 12.938314 h -3.830717 -3.429321 l 0.03875,-12.938314 z"
                        id="path1-2-9" />
                </g>
            </g>
        </SvgIcon>
    );
};

