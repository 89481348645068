import { Link } from '@solidjs/router';
import clsx from 'clsx';
import { For, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { WrappedImage } from '../image-component/wrapped-image';
import { BlogPostIntroProps, CategoryType } from './blog-post-intro.d';
import dayjs from 'dayjs';
import { ColorName } from '../../style/color-classes';
import tailwindConfig from '../../../tailwind.config';

export const BlogPostIntroContainer = (props: BlogPostIntroProps) => {
    const backgroundColorClass = 'bg-' + props.backgroundColor;
    const { pageBlogArchiveUrl, viewport } = useContext(AppContext);

    const isMobile = () => viewport.width <= tailwindConfig.theme.extend.screenValues.tabletValue;
    const isTablet = () => viewport.width > tailwindConfig.theme.extend.screenValues.tabletValue && viewport.width <= tailwindConfig.theme.extend.screenValues.desktopValue;
    return (
        <ErrorCatcher componentName="BlogPost">
            <section class="px-containerPadding">
                <div class="max-w-wide mx-auto">
                    <div class="flex gap-4 text-neutral-700">
                        {props.readingTime ? (
                            <>
                                <Text tag="span" disableMargins={true}>
                                    {props.readingTime + ' min läsning'}
                                </Text>
                            </>
                        ) : null}
                    </div>
                    <div class="mb-8 mt-4">
                        <Heading disableMaxWidth={true} tag="h1" classes="mt-0" fontSize="xxl">
                            {props.postTitle}
                        </Heading>
                    </div>
                    <Text disableMaxWidth={true} fontSize="xl">
                        {props.preamble}
                    </Text>
                    <div class="mb-8"></div>

                    <Show when={props.postType === 'personal'}>
                        <div class="border-b-1 border-primary w-full text-center leading-none">
                            <span class={clsx('text-paragraphXS leading-none bg-primary-100 p-2 translate-y-[14px] inline-block')}>
                                Skrivet av
                            </span>
                        </div>
                    </Show>

                    <div class="tablet:mb-8 mb-xl">
                        <div
                            class={clsx(
                                'tablet:py-8 flex',
                                { 'flex-row': props.postType === 'corporate' },
                                { 'flex-col': props.postType === 'personal' },
                                ' tablet:flex-row tablet:items-center gap-xs flex-wrap items-center py-0',
                            )}
                        >
                            <div
                                class={clsx(
                                    'flex',
                                    { 'flex-row': props.postType === 'corporate' },
                                    { 'flex-col': props.postType === 'personal' },
                                    'tablet:flex-row',
                                    'items-center',
                                    'tablet:items-center',
                                    'mt-s',
                                    'w-full',
                                    'tablet:w-auto',
                                )}
                            >
                                <div class={clsx('h-24 w-24 items-center rounded-full mr-4')}>
                                    {props?.author?.profilePicture && (
                                        <WrappedImage
                                            image={props.author.profilePicture}
                                            aspectRatio="square"
                                            renderWidth="100%"
                                            renderHeight="auto"
                                            roundCorners="full"
                                            width="100px"
                                            height="auto"
                                        />
                                    )}
                                </div>
                                <div class={clsx('flex flex-col justify-center text-center', { 'm-auto': props.postType === 'personal' })}>
                                    <Text align={isMobile() && props.postType === 'personal' ? 'center' : 'left'} fontSize="l" tag="span" disableMargins={true}>
                                        {props?.author?.visibleName}
                                    </Text>
                                    <Text
                                        align={isMobile() && props.postType === 'personal' ? 'center' : 'left'}
                                        fontSize="s"
                                        tag="span"
                                        color={ColorName.Neutral600}
                                        disableMargins={true}
                                    >
                                        {props?.author?.visibleTitle}
                                    </Text>
                                    {props.date && (
                                        <Text
                                            align={isMobile() && props.postType === 'personal' ? 'center' : 'left'}
                                            fontSize="s"
                                            tag="span"
                                            color={ColorName.Neutral600}
                                            disableMargins={true}
                                        >
                                            {dayjs(new Date(props.date)).format('D MMM, YYYY')}
                                        </Text>
                                    )}
                                </div>
                            </div>

                            {pageBlogArchiveUrl && (
                                <div class="tablet:ml-auto ml-0 flex flex-wrap items-center gap-2">
                                    <For each={props?.categories}>
                                        {(category: CategoryType) => (
                                            <>
                                                <Link  href={pageBlogArchiveUrl + '?category=' + category.slug}>
                                                    <span innerHTML={category.name} class="border-1 border-primary-600 px-s text-primary-600 hover:bg-primary-600 hover:text-primary-100 text-medium inline-block rounded-l py-[0.31rem] transition-colors duration-200"/>
                                                </Link>
                                            </>
                                        )}
                                    </For>
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="tablet:mb-12 mb-4">
                        {props?.featuredImage && <WrappedImage image={props.featuredImage} aspectRatio="original" width="1000px" height="auto" />}
                    </div>
                </div>
            </section>
        </ErrorCatcher>
    );
};
