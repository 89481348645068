import { For } from 'solid-js';
import { MenuItem } from '../menu-types';
import { MobileSlideSubMenuItem } from './mobile-slide-sub-menu-item';

export const MobileSlideSubMenu = (props: MobileSlideSubMenuProps) => {
    return (
        <div data-identifier="mobile-slide-sub-menu" class="pl-16">
            <For each={props.menuItems}>{(menuItem) => <MobileSlideSubMenuItem menuItem={menuItem} />}</For>
        </div>
    );
};
type MobileSlideSubMenuProps = {
    menuItems?: MenuItem[];
};
