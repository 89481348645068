// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/*
 * This high-level component can be used to trigger a function when the obect is visible.
 * It has a very simple fallback for browsers that dont support the IntersectionObserver,
 * it just triggers the visible function directly.
 *
 */

import { createEffect } from 'solid-js';
import { isServer } from 'solid-js/web';

type IntersectionObserverComponentProps = {
    children: any;
    element?: {};
    onVisible: any;
    threshold: number;
    root?: string;
    className?: string;
    style?: {};
    oneTime?: boolean;
    id?: boolean;
};

const IntersectionObserverComponent = (props: IntersectionObserverComponentProps) => {
    let io: any;

    createEffect(() => {
        const { root, threshold, onVisible, oneTime } = props;
        if (io) {
            register(root, threshold, io, onVisible, oneTime);
        }
    });

    const extraProps: any = {};
    if (props.id) {
        extraProps.id = props.id;
    }
    if (props.className) {
        extraProps.className = props.className;
    }
    if (props.style) {
        extraProps.style = props.style;
    }

    let element;

    if (isServer) {
        element = <div {...extraProps}>{props.children}</div>;
    } else {
        element = (
            <div {...extraProps} ref={io}>
                {props.children}
            </div>
        );
    }

    return element;
};

const create = (root, threshold) => {
    const intersectionOptions = {
        threshold: [threshold],
    };

    if (root) {
        intersectionOptions.root = document.querySelector(root);
    }

    // This handles when an element is visible.
    // If its observed we asume it has a visible function and we call it in a crude way.
    if (window.IntersectionObserver) {
        return new IntersectionObserver(function (entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.intersectionRatio > 0) {
                    if (typeof entry.target.__intersectionObserverInfo.onVisible === 'function') {
                        if (!entry.target.__intersectionObserverInfo.done) {
                            entry.target.__intersectionObserverInfo.onVisible(entry);
                            if (entry.target.__intersectionObserverInfo.oneTime) {
                                entry.target.__intersectionObserverInfo.done = true;
                                delete entry.target.__intersectionObserverInfo.onVisible;
                            }
                        }
                    }
                } else {
                    if (typeof entry.target.__intersectionObserverInfo.onVisible === 'function') {
                        entry.target.__intersectionObserverInfo.onVisible(false);
                    }
                }
            });
        }, intersectionOptions);
    }

    return {
        observe: (target) => {
            if (target) {
                if (typeof target.__intersectionObserverInfo.onVisible === 'function') {
                    target.__intersectionObserverInfo.onVisible();
                    delete target.__intersectionObserverInfo.onVisible;
                }
            }
        },
    };
};

const intersectionObserverList = {};

const register = (root, threshold = 0.5, element, onVisible, oneTime = true) => {
    const key = `${root ? root : 'root'}-${threshold}`;

    const intersectionObserver = (intersectionObserverList[key] = intersectionObserverList[key] || create(root, threshold));

    if (!element.__intersectionObserverInfo) {
        element.__intersectionObserverInfo = {
            onVisible,
            oneTime,
        };
        intersectionObserver.observe(element);
    }
};

export default IntersectionObserverComponent;
