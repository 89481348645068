import { Tag } from '../../ui-components/tag/tag';

export const getPostType = (type: string) => {
    switch (type) {
        case 'page': return 'Sida';
        case 'news': return 'Nyhet';
        case 'blog': return 'Blogg';
        case 'project': return 'Projekt';
    }
    return type;
};

export const SearchResult = (props: SearchResultProps) => {
    return (
        <a
            class="active:bg-accent1-100 hover:bg-accent1-100 rounded-m animate-fade-in-move-up flex flex-col space-y-3 p-3 transition-colors duration-300 ease-in-out"
            href={props.href}
        >
            <p class="text-headerS font-bold">{props.postTitle}</p>
            <p class="text-paragraphS line-clamp-2 text-neutral-600">{props.postContent}</p>
            <Tag text={getPostType(props.postType)} />
        </a>
    );
};

type SearchResultProps = {
    href: string;
    postTitle: string;
    postType: string;
    postContent: string;
};
