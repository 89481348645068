import { A } from '@solidjs/router';
import { MenuItem } from '../menu-types';

export const MobileSlideSubMenuItem = (props: MobileSlideSubMenuItemProps) => {
    return (
        <div data-identifier="mobile-slide-sub-menu-item" class="text-mobileSubMenu tablet:text-tabletSubMenu font-normal">
            <A class="inline-block px-2 py-3 font-medium" href={props.menuItem.url}>
                {props.menuItem.title}
            </A>
        </div>
    );
};
type MobileSlideSubMenuItemProps = {
    menuItem: MenuItem;
};
