import clsx from 'clsx';
import { CopyIcon } from '../../components/icons-library/copy';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Show, createSignal } from 'solid-js';

export const CodeShowcase = (props: any) => {

    const [codeCopied, setCodeCopied] = createSignal(false);

    const copyText = async () => {
        const codeCopy = props.code.replace('\n<pre class="wp-block-code"><code>', '').replace('</code></pre>\n', '');
        try {
            await navigator.clipboard.writeText(codeCopy);
            
            setCodeCopied(true);
            setTimeout(() => { 
                setCodeCopied(false);
            }, 4000);
            
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <ErrorCatcher componentName="CodeShowcase">
            <div
                class={clsx(
                    'border-1',
                    'border-primary',
                    'tablet:rounded-l',
                    'rounded-m',
                    'p-s',
                    'pt-l',
                    'tablet:p-l',
                    'tablet:pb-[3rem]',
                    'shadow-speechBubble',
                    'relative',
                    'mb-16',
                    'mt-8',
                )}
            >
                <Show when={codeCopied()}>
                    <span class="text-paragraphXS animate-wait-around-and-fade-out absolute right-10 top-3">Text kopierad till clipboard!</span>
                </Show>
                <button aria-label="Kopiera koden till clipboard" class="absolute right-0 top-0 p-4" onClick={copyText}>
                    <CopyIcon />
                </button>
                <div class="font-monospace tablet:text-code text-codeMobile" innerHTML={props.code} />
            </div>
        </ErrorCatcher>
    );
};

CodeShowcase.parseProps = (atts: any) => {
    return {
        code: atts.innerHTML ? atts.innerHTML : '',
    };
};
