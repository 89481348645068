import { mergeProps } from 'solid-js';
import { CustomLinkProps } from './link.d';
// import urlMatches from '../../tools/url-match';
import { ErrorCatcher } from '../../tools/error-catcher';

const defaultProps = {
    opensInNewTab: false,
    darkMode: false,
    noBlockSpacing: false,
};


export const Link = (componentProps: CustomLinkProps) => {
    const props = mergeProps(defaultProps, componentProps);

    if (!props.url || !props.label) {
        return <></>;
    }

    return (
        <ErrorCatcher componentName='Link'>
            <a href={props.url} /* active={urlMatches(props.url)} */>
                {props.label}
            </a>
        </ErrorCatcher>
    );
};

Link.parseProps = (atts: any) => {    
    let anchorLink = '';
    if (atts?.url?.includes('#')) {
        anchorLink = atts.url.replace('#', '');        
    }

    return {
        ...atts,
        anchorLink,
    };
};
