import { Match, Switch, createEffect } from 'solid-js';
import { fillColorClasses } from '../../style/color-classes';
import { ColorName } from '../../style/color-classes';
import clsx from 'clsx';

export enum DividerType {
    Wave = 'wave',
    BigWave = 'bigWave',
    Curve = 'curve',
}

type SectionDividerProps = {
    topDivider?: DividerType;
    bottomDivider?: DividerType;
    fill?: ColorName;
    topDividerFlipHorisontally?: boolean;
    bottomDividerFlipHorisontally?: boolean;
    classes?: string;
    isFooter?: boolean;
};

export const SectionDivider = (props: SectionDividerProps) => {
    const flipHorisontally = 'transform scale-x-[-1]';
    const topDividerFlipHorisontally = props.topDividerFlipHorisontally && flipHorisontally;
    const bottomDividerFlipHorisontally = props.bottomDividerFlipHorisontally && flipHorisontally;
    const fillColorClassName = props.fill && fillColorClasses[props.fill];


    return (
        <div>
            <Switch>
                <Match when={props.topDivider === 'wave'}>
                    <div class="h-[1px]">
                        <svg
                            class={clsx('tablet:h-28 tablet:-top-28 absolute -top-16 h-16 w-full translate-y-[1px]', topDividerFlipHorisontally, props.classes)}
                            preserveAspectRatio="none"
                            viewBox="0 0 3265 81"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 46.006h136.041c136.041 0 379.341 2.997 651.423-6.702C1059.55 29.604 1360.41-2.06 1632.5 4.407c272.08 6.466 544.16 48.066 816.24 60.998 272.08 12.932 544.17 6.466 680.21 3.233l136.04-3.233V81H0V46.006Z"
                                class={fillColorClassName}
                            />
                        </svg>
                    </div>
                </Match>

                <Match when={props.topDivider === 'bigWave'}>
                    <div class="h-[1px]">
                        <svg
                            class={clsx('tablet:h-36 tablet:-top-36 absolute -top-24 h-24 w-full translate-y-[1px]', topDividerFlipHorisontally, props.classes)}
                            preserveAspectRatio="none"
                            fill="none"
                            viewBox="0 0 1727 175"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                class={fillColorClassName}
                                d="M1603.07 157.321H1727V175H-0.00012207V57.6847C78.5631 31.4632 161.298 10.7918 239.861 3.19838C487.718 -20.758 761.794 96.5542 1009.65 132.489C1257.51 168.423 1479.14 157.321 1603.07 157.321Z"
                            />
                        </svg>
                    </div>
                </Match>

                <Match when={props.bottomDivider === 'curve'}>
                    <div class="h-[1px]">
                        <svg
                            class={clsx(
                                'tablet:h-20 tablet:-bottom-20 absolute -bottom-8 z-10 h-8 w-full -translate-y-[1px]',
                                bottomDividerFlipHorisontally,
                                props.classes,
                            )}
                            preserveAspectRatio="none"
                            viewBox="0 0 1728 107"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                class={fillColorClassName}
                                d="M1728 0C1254.26 58.6693 884.421 85.1696 590 79.5008C347.462 74.8309 156.104 48.3307 0 0H1728Z"
                            />
                        </svg>
                    </div>
                </Match>
            </Switch>
        </div>
    );
};
