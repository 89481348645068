import { Column } from '../../grid-system/column/column';
import { gql } from 'graphql-request';
import { useContext, Show, For, createSignal, createEffect, onMount } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Text } from '../../ui-components/text/text';
import { A, Link, useLocation } from '@solidjs/router';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Heading } from '../../ui-components/heading/heading';
import { Button } from '../../ui-components/button/button';
import { InstagramIcon } from '../../components/icons-library/social-media/instagram';
import { LinkedInIcon } from '../../components/icons-library/social-media/linkedin';
import { MenuItem } from '../menu/menu-types';
import { DividerType, SectionDivider } from '../../grid-system/section-divider/section-divider';
import { ButtonColorName, ColorName, bgColorClasses, borderColorClasses, fillColorClasses, textColorClasses } from '../../style/color-classes';
import clsx from 'clsx';
import tailwindConfig from '../../../tailwind.config';
import { PlusIcon } from '../icons-library/plus';
import { ExpandingContainer } from '../../ui-components/expanding-container/expanding-container';

const GET_FOOTER_QUERY = gql`
    query GetFooter($key: String!) {
        footer: resource(key: $key) {
            content
            key
        }
    }
`;

const GET_MENUS_QUERY = gql`
    query GetPrimaryMenu($type: [String]) {
        menu: resources(type: $type) {
            content
            key
        }
    }
`;

type footerDataType = {
    footerAddress: string;
    footerBottomText: string;
    footerButtonText: string;
    footerButtonURL: string;
    footerColumn1Heading: string;
    footerColumn2Heading: string;
    footerColumn3Heading: string;
    footerColumn3Description: string;
    footerColumn4Heading: string;
    footerDescription: string;
    footerHeading: string;
    footerInstagramUrl: string;
    footerLinkedInUrl: string;
    footerPhoneNumberDisplayed: string;
    footerPhoneNumberLinked: string;
    footerEmail: string;
};

export const FooterContainer = (props: any) => {
    const { pageTheme, createCachedResource, viewport } = useContext(AppContext);
    const [footerData] = createCachedResource(GET_FOOTER_QUERY, () => ({ key: '/wp-json/rawb/v1/footer' }), true);
    const footerContent: footerDataType = footerData()?.footer?.content;
    const [menuContent] = createCachedResource(GET_MENUS_QUERY, () => ({ type: ['menu'] }), true);
    const [footerColumnMenuContentData, setFooterColumnMenuContentData] = createSignal<MenuItem[]>([] as MenuItem[]);
    const [footerBottomMenuContentData, setBottomMenuContentData] = createSignal<MenuItem[]>([] as MenuItem[]);
    const [activeColumn, setActiveColumn] = createSignal(0);
    const [socialMedioIconColor, setSocialMediaIconColor] = createSignal('');

    const isTablet = () => {
        if (viewport.width >= tailwindConfig.theme.extend.screenValues.tabletValue) {
            return true;
        } else {
            return false;
        }
    };

    const handleActivateColumn = (e: Event, ID: number) => {
        setActiveColumn(activeColumn() === ID ? 0 : ID);
    };

    menuContent()?.menu.forEach((menu: any) => {
        if (menu.content.externalId === 'menus-footer_column_menu') {
            setFooterColumnMenuContentData(menu.content.menu);
        }
        if (menu.content.externalId === 'menus-footer_bottom_menu') {
            setBottomMenuContentData(menu.content.menu);
        }
    });

    const columnClasses = () =>
        clsx(
            'col-span-4',
            'flex flex-col tablet:gap-xs',
            { 'border-b-superThin': !isTablet() },
            borderColorClasses[pageTheme()?.footerColors.socialMediaButtonBackgroundColor as ColorName],
        );
    const columnHeadingClasses = () => clsx(textColorClasses[pageTheme()?.footerColors.headingColor as ColorName]);

    const getSocialMediaIconColor = () => {
        for (const [key, value] of Object.entries(tailwindConfig.theme.extend.colorValuesForSvg)) {
            if (key === pageTheme()?.footerColors.headingColor) {
                return value;
            }
        }
    };
    createEffect(() => {
        const socialMediaColor = getSocialMediaIconColor();
        if (socialMediaColor) setSocialMediaIconColor(socialMediaColor);
    });

    return (
        <ErrorCatcher componentName="Footer">
            <footer
                class={clsx(
                    'relative',
                    'tablet:text-paragraphS',
                    'text-paragraphM',
                    { 'tablet:mt-48 mt-32': props?.useFooterMargin === true }, // same as the height of the section divider
                    bgColorClasses[pageTheme()?.footerColors.bgColor as ColorName],
                    textColorClasses[pageTheme()?.footerColors.textColor as ColorName],
                )}
            >
                <SectionDivider isFooter={true} classes={fillColorClasses[pageTheme()?.footerColors.bgColor as ColorName]} topDivider={DividerType.BigWave} />
                <div class="px-containerPadding">
                    <div class="max-w-maximal mx-auto">
                        <Show when={footerContent && pageTheme}>
                            <Show when={!props.limitFooterContent}>
                                <div class="pb-m pt-l">
                                    {footerContent.footerHeading && (
                                        <Heading
                                            classes={clsx('mb-8')}
                                            colorName={pageTheme()?.footerColors.headingColor as ColorName}
                                            fontSize="xl"
                                            align={isTablet() ? 'left' : 'center'}
                                            tag="h4"
                                        >
                                            {footerContent.footerHeading}
                                        </Heading>
                                    )}
                                    {footerContent.footerDescription && (
                                        <Text fontSize="l" align={isTablet() ? 'left' : 'center'} color={pageTheme()?.footerColors.headingColor as ColorName}>
                                            {footerContent.footerDescription}
                                        </Text>
                                    )}
                                    {footerContent.footerButtonText && (
                                        <div class={clsx({ 'text-center': !isTablet() })}>
                                            <Button
                                                buttonSize="large"
                                                variant="solid"
                                                color={pageTheme()?.footerColors.buttonColor as ButtonColorName}
                                                arrow={true}
                                                url={footerContent.footerButtonURL}
                                                label={footerContent.footerButtonText}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Show>

                            <section
                                class={clsx('tablet:gap-m', 'laptop:gap-xl', 'tablet:grid-cols-16', 'tablet:mb-xl', 'my-8', 'grid', 'grid-cols-1', {
                                    'mt-xl': props.limitFooterContent,
                                })}
                            >
                                {/* Column 1 */}
                                <div class={clsx(columnClasses(), 'tablet:max-bigTablet:col-span-4')}>
                                    <Heading disableMaxWidth={true} fontSize={isTablet() ? 's' : 'm'} classes={columnHeadingClasses()} tag="h5">
                                        <Show fallback={footerContent.footerColumn1Heading} when={!isTablet()}>
                                            <button
                                                aria-label={(activeColumn() === 1 ? 'Dölj' : 'Visa') + ' innehåll för ' + footerContent.footerColumn1Heading}
                                                class="flex w-full items-center justify-between py-4"
                                                onClick={(e) => handleActivateColumn(e, 1)}
                                            >
                                                {footerContent.footerColumn1Heading}
                                                <PlusIcon
                                                    class={clsx('-translate-x-2', { 'rotate-45': activeColumn() === 1 }, 'transition-transform')}
                                                    width="1rem"
                                                    height="1rem"
                                                />
                                            </button>
                                        </Show>
                                    </Heading>
                                    <ExpandingContainer disabled={isTablet()} open={isTablet() || activeColumn() === 1}>
                                        <div class="gap-xs tablet:p-0 tablet:mb-2 flex p-2 pb-6">
                                            {footerContent.footerLinkedInUrl && (
                                                <a class="mb-2" aria-label="Följ oss på LinkedIn" target="_blank" href={footerContent.footerLinkedInUrl}>
                                                    <div
                                                        class={clsx(
                                                            bgColorClasses[pageTheme()?.footerColors.socialMediaButtonBackgroundColor as ColorName],
                                                            'rounded-s',
                                                            'tablet:p-4',
                                                            'opacity-75',
                                                            'hover:opacity-100',
                                                            'transition-opacity',
                                                            'duration-200',
                                                            'ease-in-out',
                                                            'p-3',
                                                        )}
                                                    >
                                                        <LinkedInIcon
                                                            color={socialMedioIconColor()}
                                                            width={isTablet() ? '1.75rem' : '1.25rem'}
                                                            height={isTablet() ? '1.75rem' : '1.25rem'}
                                                        />
                                                    </div>
                                                </a>
                                            )}
                                            {footerContent.footerInstagramUrl && (
                                                <a class="mb-2" aria-label="Följ oss på Instagram" target="_blank" href={footerContent.footerInstagramUrl}>
                                                    <div
                                                        class={clsx(
                                                            bgColorClasses[pageTheme()?.footerColors.socialMediaButtonBackgroundColor as ColorName],
                                                            'rounded-s',
                                                            'tablet:p-4',
                                                            'p-3',
                                                            'opacity-75',
                                                            'hover:opacity-100',
                                                            'transition-opacity',
                                                            'duration-200',
                                                            'ease-in-out',
                                                        )}
                                                    >
                                                        <InstagramIcon
                                                            color={socialMedioIconColor()}
                                                            width={isTablet() ? '1.75rem' : '1.25rem'}
                                                            height={isTablet() ? '1.75rem' : '1.25rem'}
                                                        />
                                                    </div>
                                                </a>
                                            )}
                                        </div>
                                    </ExpandingContainer>
                                </div>

                                {/* Column 2 */}
                                <div class={clsx(columnClasses(), 'tablet:max-bigTablet:hidden')}>
                                    <Heading disableMaxWidth={true} fontSize={isTablet() ? 's' : 'm'} classes={columnHeadingClasses()} tag="h5">
                                        <Show fallback={footerContent.footerColumn2Heading} when={!isTablet()}>
                                            <button
                                                aria-label={(activeColumn() === 2 ? 'Dölj' : 'Visa') + ' innehåll för ' + footerContent.footerColumn2Heading}
                                                class="flex w-full items-center justify-between py-4"
                                                onClick={(e) => handleActivateColumn(e, 2)}
                                            >
                                                {footerContent.footerColumn2Heading}
                                                <PlusIcon
                                                    class={clsx('-translate-x-2', { 'rotate-45': activeColumn() === 2 }, 'transition-transform')}
                                                    width="1rem"
                                                    height="1rem"
                                                />
                                            </button>
                                        </Show>
                                    </Heading>
                                    <ExpandingContainer disabled={isTablet()} open={isTablet() || activeColumn() === 2}>
                                        <nav class="tablet:p-0 p-2 pb-6">
                                            <For each={footerColumnMenuContentData()}>
                                                {(item, index) => (
                                                    <div class="mb-2">
                                                        <A
                                                            class={clsx(
                                                                'text-[1rem]',
                                                                'hover:underline focus:underline',
                                                                textColorClasses[pageTheme()?.footerColors.linkColor as ColorName],
                                                            )}
                                                            href={item.url}
                                                        >
                                                            {item.title}
                                                        </A>
                                                    </div>
                                                )}
                                            </For>
                                        </nav>
                                    </ExpandingContainer>
                                </div>

                                {/* Column 3 */}
                                <div class={clsx(columnClasses(), 'tablet:max-bigTablet:col-span-6')}>
                                    <Heading disableMaxWidth={true} fontSize={isTablet() ? 's' : 'm'} classes={columnHeadingClasses()} tag="h5">
                                        <Show fallback={footerContent.footerColumn3Heading} when={!isTablet()}>
                                            <button
                                                aria-label={(activeColumn() === 3 ? 'Dölj' : 'Visa') + ' innehåll för ' + footerContent.footerColumn3Heading}
                                                class="flex w-full items-center justify-between py-4"
                                                onClick={(e) => handleActivateColumn(e, 3)}
                                            >
                                                {footerContent.footerColumn3Heading}
                                                <PlusIcon
                                                    class={clsx('-translate-x-2', { 'rotate-45': activeColumn() === 3 }, 'transition-transform')}
                                                    width="1rem"
                                                    height="1rem"
                                                />
                                            </button>
                                        </Show>
                                    </Heading>

                                    <ExpandingContainer disabled={isTablet()} open={isTablet() || activeColumn() === 3}>
                                        <div class="tablet:p-0 p-2 pb-6">
                                            <Text color={pageTheme()?.footerColors.descriptionColor} fontSize={isTablet() ? 's' : 'm'} disableMargins={false}>
                                                {footerContent.footerColumn3Description}
                                            </Text>
                                            <div class="mb-2 block">
                                                <Button
                                                    buttonSize="small"
                                                    aria-label="Prenumerera på vårt nyhetsbrev"
                                                    arrow={true}
                                                    color={pageTheme()?.footerColors.secondaryButtonColor}
                                                    variant="ghost"
                                                    label="Prenumerera"
                                                    url="/nyhetsbrev"
                                                />
                                            </div>
                                        </div>
                                    </ExpandingContainer>
                                </div>

                                {/* Column 4 */}
                                <div class={clsx(columnClasses(), 'tablet:max-bigTablet:col-span-6')}>
                                    <Heading disableMaxWidth={true} fontSize={isTablet() ? 's' : 'm'} classes={columnHeadingClasses()} tag="h5">
                                        <Show fallback={footerContent.footerColumn4Heading} when={!isTablet()}>
                                            <button
                                                aria-label={(activeColumn() === 4 ? 'Dölj' : 'Visa') + ' innehåll för ' + footerContent.footerColumn4Heading}
                                                class="flex w-full items-center justify-between py-4"
                                                onClick={(e) => handleActivateColumn(e, 4)}
                                            >
                                                {footerContent.footerColumn4Heading}
                                                <PlusIcon
                                                    class={clsx('-translate-x-2', { 'rotate-45': activeColumn() === 4 }, 'transition-transform')}
                                                    width="1rem"
                                                    height="1rem"
                                                />
                                            </button>
                                        </Show>
                                    </Heading>

                                    <ExpandingContainer disabled={isTablet()} open={isTablet() || activeColumn() === 4}>
                                        <div class="tablet:p-0 p-2 pb-6">
                                            {footerContent.footerAddress && (
                                                <Text color={pageTheme()?.footerColors.linkColor} disableMargins={true} classes="mb-2">
                                                    {footerContent.footerAddress}
                                                </Text>
                                            )}
                                            {footerContent.footerPhoneNumberDisplayed && (
                                                <div class="mb-2">
                                                    <a
                                                        class={`hover:underline focus:underline text-${pageTheme()?.footerColors.linkColor}`}
                                                        href={'tel:' + footerContent.footerPhoneNumberLinked}
                                                    >
                                                        {footerContent.footerPhoneNumberDisplayed}
                                                    </a>
                                                </div>
                                            )}
                                            {footerContent.footerEmail && (
                                                <div class="mb-2">
                                                    <a
                                                        class={`hover:underline focus:underline text-${pageTheme()?.footerColors.linkColor}`}
                                                        href={'mailto:' + footerContent.footerEmail}
                                                    >
                                                        {footerContent.footerEmail}
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </ExpandingContainer>
                                </div>
                            </section>
                        </Show>
                    </div>
                    <nav id="footer_bottom_nav" class="mx-auto flex justify-center gap-10 pb-8 text-center">
                        <For each={footerBottomMenuContentData()}>
                            {(item, index) => (
                                <div>
                                    <A class="tablet:no-underline block p-1 underline hover:underline focus:underline" href={item.url}>
                                        {item.title}
                                    </A>
                                </div>
                            )}
                        </For>
                    </nav>
                </div>
            </footer>
        </ErrorCatcher>
    );
};
