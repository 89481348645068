import { A, useLocation } from '@solidjs/router';
import clsx from 'clsx';
import { For, Match, Show, Switch, createEffect, createSignal, onCleanup, onMount, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import {
    ColorName,
    bgColorClasses,
    textColorClasses,
    bgHoverColorClasses,
    bgFocusColorClasses,
    borderColorClasses,
    borderHoverColorClasses,
    borderFocusColorClasses,
} from '../../../style/color-classes';
import { ChevronUpBoldIcon } from '../../icons-library/chevron-up-bold';
import { SiteLogo } from '../../icons-library/site-logo';
import { MenuProps } from '../menu-types.d';
import { SearchIcon } from '../../icons-library/search';
import { MobileSearch } from '../../search/search';
import { Portal } from 'solid-js/web';

export const MenuDesktop = (props: MenuProps) => {
    const { pageTheme } = useContext(AppContext);

    const navClassNames = 'flex justify-center items-center gap-1 laptop:gap-2 laptop:absolute laptop:top-0 laptop:right-0 laptop:px-8 min-h-headerDesktop';
    const middleNavClassNames = 'flex justify-center items-center gap-2 laptop:absolute min-h-headerDesktop';
    const [activeSubmenu, setActiveSubmenu] = createSignal(0);
    const [colorClasses, setColorClasses] = createSignal('');

    // 3 states to make sure we hide it when the page is loaded
    const [searchActive, setSearchActive] = createSignal<'init' | 'active' | 'inactive' >('init');

    const location = useLocation();

    createEffect(() => {
        location.pathname && setActiveSubmenu(0);
        let myColorClasses = textColorClasses[pageTheme()?.headerColors.linkColor as ColorName];
        //myColorClasses += pageTheme().transparentSiteHeader ? '' : ' ' + bgColorClasses[pageTheme()?.colors.bgColor as ColorName];
        myColorClasses += pageTheme().transparentSiteHeader; // ? '' : ' ' + bgColorClasses[pageTheme()?.colors.bgColor as ColorName];
        setColorClasses(myColorClasses);
    });

    const toggleBodyScroll = (disable: boolean) => {
        if (disable) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    };

    createEffect(() => {
        const bodyElement = document.querySelector('body');

        if (bodyElement) {
            if (searchActive() === 'active') {
                bodyElement.style.overflow = 'hidden';
            } else {
                bodyElement.style.position = 'auto';
            }
        }

        onCleanup(() => {
            toggleBodyScroll(false);
        });
    });

    const handleOnClick = (e: Event, ID: number) => {
        e.stopImmediatePropagation();
        setActiveSubmenu(activeSubmenu() === ID ? 0 : ID);
    };

    let dropdownMenuRef: HTMLDivElement | undefined;
    const handleOutsideClick = () => {
        const handleClickOutside = (event: Event) => {
            if (!dropdownMenuRef?.contains(event.target as Node) && activeSubmenu() !== 0) {
                setActiveSubmenu(0);
            }
        };

        window.addEventListener('click', handleClickOutside);

        onCleanup(() => {
            window.removeEventListener('click', handleClickOutside);
        });
    };

    onMount(() => {
        handleOutsideClick();
    });

    const buttonClasses = () =>
        clsx(
            'flex items-center rounded-s px-6 py-4 leading-none',
            bgHoverColorClasses[pageTheme()?.headerColors.buttonHoverColor as ColorName],
            bgFocusColorClasses[pageTheme()?.headerColors.buttonHoverColor as ColorName],
        );
    const secondaryButtonClasses = 'flex items-center rounded-s px-6 py-4 leading-none';
    const transitionClasses = 'transition-color ease-in-out duration-300';

    const getSearchWrapperClasses = () => {
        const classes = clsx(
            'fixed right-3 top-3 z-50',
            { ['animate-fade-in-from-right']: searchActive() === 'active' },
            { ['animate-fade-out-to-right']: searchActive() === 'inactive' },
            { ['hidden']: searchActive() === 'init' },
        );
        return classes;
    };

    return (
        <Show when={props.primaryMenuData} fallback={<div>No menu</div>}>
            <div
                class={clsx(
                    'flex',
                    'font-medium',
                    'justify-end',
                    'laptop:justify-center',
                    'gap-2',
                    'relative',
                    'top-0',
                    'left-0',
                    'w-full',
                    'z-10',
                    'min-h-headerDesktop',
                    'laptop:px-8',
                    'px-2',
                    'text-desktopMenu',
                    colorClasses(),
                )}
            >
                <div class="min-h-headerDesktop laptop:px-8 laptop:absolute left-0 top-0 mr-auto flex items-center px-2">
                    <A aria-label="Gå till startsidan" class="px-4 py-4" href="/">
                        <SiteLogo width="7.14rem" height="2.69rem" />
                    </A>
                </div>

                <nav class={middleNavClassNames}>
                    <For each={props.primaryMenuData}>
                        {(item, index) => (
                            <Switch
                                fallback={
                                    <A class={clsx(buttonClasses(), transitionClasses)} href={item.url}>
                                        {item.title}
                                    </A>
                                }
                            >
                                <Match when={item.children}>
                                    <div class="relative">
                                        <button
                                            aria-label={(activeSubmenu() === item.ID ? 'Dölj' : 'Visa') + ' undermeny för ' + item.title}
                                            class={clsx(buttonClasses(), transitionClasses)}
                                            data-index={index()}
                                            onClick={(e) => handleOnClick(e, item.ID)}
                                        >
                                            {item.title}
                                            <span class={clsx('ml-[0.625rem]', 'transform', 'translate-y-[2px]')}>
                                                <ChevronUpBoldIcon
                                                    width="10px"
                                                    height="100%"
                                                    class={clsx('origin-center transform transition-transform duration-300', {
                                                        'rotate-180': activeSubmenu() === item.ID,
                                                    })}
                                                />
                                            </span>
                                        </button>

                                        <Show when={item.ID === activeSubmenu()}>
                                            <div class={clsx('absolute', 'animate-fade-in-move-up')} ref={dropdownMenuRef}>
                                                <div class={'shadow-subtle text-[1.125rem] min-w-[18rem] rounded-s bg-white p-3 text-black'}>
                                                    <ul class="submenu">
                                                        {item?.children?.map((subItem) => (
                                                            <li class="p-0">
                                                                <A
                                                                    class={clsx(
                                                                        'block',
                                                                        'p-3',
                                                                        'hover:bg-blackTransparent-06',
                                                                        'focus:bg-blackTransparent-06',
                                                                        'hover:text-primary',
                                                                        'focus:text-primary',
                                                                        'rounded-xs',
                                                                        'text-neutral-900',
                                                                        transitionClasses,
                                                                    )}
                                                                    href={subItem.url}
                                                                >
                                                                    {subItem.title}
                                                                </A>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Show>
                                    </div>
                                </Match>
                            </Switch>
                        )}
                    </For>
                </nav>

                <nav class={clsx(navClassNames)}>
                    <For each={props.secondaryMenuData}>
                        {(item, index) => (
                            <Show
                                fallback={
                                    <A
                                        class={clsx(
                                            secondaryButtonClasses,
                                            transitionClasses,
                                            borderColorClasses[pageTheme()?.headerColors.contactBorderColor as ColorName],
                                            borderHoverColorClasses[pageTheme()?.headerColors.contactBorderHoverColor as ColorName],
                                            borderFocusColorClasses[pageTheme()?.headerColors.contactBorderHoverColor as ColorName],
                                            { 'text-desktopMenuButton border-[1.5px]': index() === 0 },
                                        )}
                                        href={item.url}
                                    >
                                        {item.title}
                                    </A>
                                }
                                when={index() !== 0}
                            >
                                <A class={clsx(buttonClasses(), transitionClasses)} href={item.url}>
                                    {item.title}
                                </A>
                            </Show>
                        )}
                    </For>

                    <button onClick={() => setSearchActive('active')} aria-label="Sök" class={clsx(buttonClasses())}>
                        <SearchIcon />
                    </button>

                    {/* Overlay div to dim screen */}
                    <Portal mount={document.querySelector('dim')}>
                        <div
                            class={`fixed inset-0 z-40 overflow-hidden bg-black transition-opacity duration-500 ease-in-out ${
                                searchActive() === 'active' ? 'opacity-50' : 'opacity-0'
                            } ${searchActive() === 'active' ? '' : 'pointer-events-none'}`}
                            onClick={() => setSearchActive('inactive')}
                        />
                        <div class={getSearchWrapperClasses()} style={{ height: 'calc(100vh - 1.5rem)', width: 'calc(100vw / 3)', bottom: '2rem' }}>
                            <MobileSearch setSearchActive={setSearchActive} />
                        </div>
                    </Portal>

                </nav>
            </div>
        </Show>
    );
};
