import { For } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';
import { ImageWordpress } from '../../types/shared';
import { ContactCard } from './contact-card/contact-card';

import clsx from 'clsx';

export type ContactCardType = {
    image: ImageWordpress;
    name: string;
    title: string;
    phoneNumber: string;
    email: string;
    quote: string;
    quotePosition: string;
};

export type ContactCardsProps = {
    contactCards: ContactCardType[];
    displayChatBubble: string;
};

export const ContactCards = (props: ContactCardsProps) => {
    const cardsAmount = props.contactCards.length;
    const columnCount = cardsAmount >= 4 ? 'grid-cols-4' : 'grid-cols-3';
    const gap = cardsAmount <= 3 ? 'gap-xl' : 'gap-m';

    let positioningClasses = '';
    if (cardsAmount <= 2) {
        positioningClasses = 'tablet:flex tablet:justify-center';
    } else {
        positioningClasses = 'tablet:grid tablet:grid-flow-row';
    }
    return (
        <ErrorCatcher componentName="ContactCards">
            <div class={clsx(positioningClasses, columnCount, gap, 'w-full')}>
                <For each={props.contactCards as ContactCardType[]}>
                    {(contactCard: ContactCardType, i) => {
                        return (
                            <ContactCard
                                displayChatBubble={props.displayChatBubble}
                                contactCard={contactCard}
                                cardsAmount={cardsAmount}
                                currentCardNumber={i()}
                            />
                        );
                    }}
                </For>
            </div>
        </ErrorCatcher>
    );
};

ContactCards.parseProps = (atts: any) => {
    return {
        contactCards: atts.contactCards,
        displayChatBubble: atts.displayChatBubble,
    };
};
